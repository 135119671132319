import WebComponent from '../../../WebComponent'
import {css,html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/Spinner/Spinner.js'
import StatistikKonsolidierungService from "../../../services/StatistikKonsolidierungService";

export default class Konsolidierung extends WebComponent {
  get translationFile() {
    return './views/Statistikdaten/Konsolidierung/i18n.json'
  }

  constructor() {
    super();
    this.statistikService = new StatistikKonsolidierungService()
  }

  connectedCallback() {
    super.connectedCallback();
    this.lastYear = new Date().getFullYear() - 1
    this.years = []
    this.backendData = {}
    this._getDataAndRender();
  }

  get css() {
    return css`

      #konsolideren-inputs {
        display: grid;
        grid-template-columns: 1fr 4fr;
        gap: 1em;
        margin-top: 4em;
        max-width: 90%;
      }

      .konsolidieren-label {
        font-weight: bold;
      }

      #year {
        max-width: 50%;
      }

      #b1 {
        max-width: 50%;
      }

      #bereitstellen-button {
        margin-left: 20%;
      }

      #konsolidieren-container {
        display: flex;
        gap: 1em;
        align-items: center;
      }
    `
  }

  getTemplate() {
    return html`
      <h1>${this.i18n.translate("statistikdaten.konsolidierung.title")}</h1>

      <isceco-form id="konsolidieren-form">
        <div slot="form-elements" id="konsolideren-inputs">
          <span class="konsolidieren-label">${this.i18n.translate('statistikdaten.konsolidierung.year')}</span>
          <isceco-dropdown
            id="year"
            name="year"
            value="${this.lastYear}"
            .items="${this.years}"
            @change="${e => this._changeYear(e)}"
            required
          ></isceco-dropdown>
          <label class="konsolidieren-label" for="b1">${this.i18n.translate('statistikdaten.konsolidierung.b1')} *</label>
          <isceco-number-input
            id="b1"
            name="b1"
            maximum-fraction-digits="${0}"
            value="${this.backendData.b1}"
            required
            ?readonly="${this.backendData.bereitgestellt}"
          ></isceco-number-input>
          <label class="konsolidieren-label" for="c1">${this.i18n.translate('statistikdaten.konsolidierung.c1')}</label>
          <isceco-textarea
            id="c1"
            name="c1"
            resize="auto"
            value="${this.backendData.c1}"
            ?readonly="${this.backendData.bereitgestellt}"
          ></isceco-textarea>
          <label class="konsolidieren-label" for="c2">${this.i18n.translate('statistikdaten.konsolidierung.c2')}</label>
          <isceco-textarea
            id="c2"
            name="c2"
            resize="auto"
            value="${this.backendData.c2}"
            ?readonly="${this.backendData.bereitgestellt}"
          ></isceco-textarea>
          <div id="konsolidieren-container">
            <isceco-button
              id="konsolidieren-button" .text="${this._getKonsolidierenButtonText()}"
              @click="${() => this._konsolidieren()}"
            ></isceco-button>
            <isceco-spinner id="konsolidieren-loader" hidden/>
          </div>
          <isceco-button
            id="bereitstellen-button" .text="${this.i18n.translate('statistikdaten.konsolidierung.button.bereitstellen')}"
            @click="${() => this._bereitstellen()}"
            ?disabled="${this.backendData.bereitgestellt}"
          ></isceco-button>
        </div>
      </isceco-form>
    `
  }

  _getDataAndRender() {
    const loadYears = this.statistikService.list('years')
      .then(years => years.map(year => ({value: year, name: year})))
      .then(years => {
        this.years = years
        if (!this.years.includes(this.lastYear)) {
          this.years.push({value: this.lastYear, name: this.lastYear})
        }
      })

    Promise.all([loadYears, this._loadData(this.lastYear)])
      .then(_ => this.render())
  }

  _changeYear(e) {
    const year = e.target.value
    this._loadData(year)
      .then(_ => this.reload())
  }

  async _loadData(year) {
    return this.statistikService.read(year)
      .then(async responseData => {
        if(!isNoDataFound(responseData)) {
          this.backendData = responseData
        } else {
          this.backendData = {}
          await this._loadSuggestion(year)
        }
      })
  }

  async _loadSuggestion(year) {
    return this.statistikService.read(year-1)
      .then(responseData => {
        this.backendData.year = year
        this.backendData.c1 = responseData.c1 ?? null
        this.backendData.c2 = responseData.c2 ?? null
        this.backendData.bereitgestellt = false
      })
  }

  _getKonsolidierenButtonText() {
    return this.backendData.bereitgestellt ? this.i18n.translate('statistikdaten.konsolidierung.button.anzeigen') : this.i18n.translate('statistikdaten.konsolidierung.button.konsolidieren')
  }

  _konsolidieren() {
    const form = document.getElementById('konsolidieren-form')
    if(!form.validate()) {
      return
    }

    const button = document.getElementById('konsolidieren-button')
    button.disabled = true
    const loader = document.getElementById('konsolidieren-loader')
    loader.hidden = false
    this.statistikService.create(form.getValues())
      .then(response => response.blob())
      .then(blob => download(`statistikdaten.zip`, blob))
      .finally(_ => {
        button.disabled = false
        loader.hidden = true
      })

  }

  _bereitstellen() {
    const year = document.getElementById('year').value

    this.statistikService.create(year, 'bereitstellen')
      .then(() =>
        showAlert(
          this.i18n.translate('statistikdaten.konsolidierung.bereitstellen.success.title'),
          this.i18n.translate('statistikdaten.konsolidierung.bereitstellen.success.message'),
        'success')
      )
      .then(_ => this._loadData(year))
      .then(_ => this.reload())
  }

}

customElements.define('vzavg-statistikdaten-konsolidierung', Konsolidierung)
