import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Switch/Switch.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '../../../components/GesuchAdresse/GesuchAdresseDiffDialog.js'
import '../../../components/GesuchAdresse/GesuchAdresseRsVonHS.js'
import '../../../components/ErrorMsg/ErrorMsg.js'
import '../../../components/PlzOrtInput/PlzOrtInput.js'
import '../../../components/DateInputWithWarning/DateInputWithWarning.js'
import '../../../components/CheckList/CheckList.js'
import ExternalBetriebSearchService from '../../../services/ExternalBetriebSearchService.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import GesuchService from '../../../services/GesuchService.js'
import StammdatenService from '../../../services/StammdatenService.js'
import WebComponent from '../../../WebComponent.js'
import VzavgFormation from '../../../common/VzavgFormation'
import VzavgPattern from '../../../common/VzavgPattern.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import DataHelper from '../Common/DataHelper.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import '../../../components/Title/Title.js'


export default class AdresseEdit extends WebComponent {

  static ADRESSE_TYPE_CODE_FIELD_ID = '#adresseTypCode'
  static UID_FIELD_ID = '#unternRegisterId'
  static SPEICHERN_BTN_ID = 'adresse-speichern'
  static TELEFON_DEFAULT = '+41 '

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.gesuchService = new GesuchService()
    this.stammdatenService = new StammdatenService()
    this.searchService = new ExternalBetriebSearchService()
    this.adressTypen = []
    this.rsProposal = {}
    this.formDataToSave = {}
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Adresse/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()

    this.gesuchId = getNavigationId()
    this.adresseId = this._getAdresseId()

    this._prepareAdressTypen()
      .then(_ => this._getAdresseAndRender())
  }

  get css() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        min-width: 21em;
        gap: 0.25em;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .buttons-column {
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: start;
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }

      .burwebSearchIcon {
        font-size: 1.5em !important;
        max-width: 2em;
        align-self: flex-end;
        padding-bottom: 0.5em;
      }

      #adresseBis {
        margin-bottom: 3em;
      }

      div.error {
        outline: 2px solid var(--isceco-color-red-500);
        border-radius: 4px;
      }
    `
  }

  getTemplate() {
    return html`
      <div>
        <isceco-button
          id="adresse-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('geschaeftsvorfall.adresse.back.to.list')}"
          @click="${_ => this._backToAdressenList()}"
        ></isceco-button>
        <isceco-dialog
          hidden
          id="adresse-confirm-change-dialog"
          description="${this.i18n.translate('geschaeftsvorfall.adresse.confirm.changes.dialog.description')}"
          confirm-button="${this.i18n.translate('geschaeftsvorfall.adresse.confirm.changes.button.confirm')}"
          cancel-button="${this.i18n.translate('geschaeftsvorfall.adresse.confirm.changes.button.cancel')}"
          @submit="${e => this._closeConfirmChangeDialog(e)}"
        ></isceco-dialog>
      </div>
      <vzavg-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.adresse.create.title')}">
        <div slot="center" class="title-content">
          <isceco-button
            id="previousAddress"
            icon="chevron left"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.gesuch.hash, id: this.gesuchId})}"
          ></isceco-button>
          <vzavg-checklist
            id="kantonCheckListChecks"
            name="kantonCheckListChecks"
            type="kanton"
            .items="${this.backendData.kantonCheckList}"
            .isChecklistOverridden="${this.backendData.isKantonChecklistOverridden}"
            .confirmCallback="${_ => this._handleKantonCheckList()}"
            .disabled="${!this.adresseId}"
          ></vzavg-checklist>
          <vzavg-checklist
            id="secoCheckListChecks"
            name="secoCheckListChecks"
            type="seco"
            .items="${this.backendData.secoCheckList}"
            .isChecklistOverridden="${this.backendData.isSecoChecklistOverridden}"
            .confirmCallback="${_ => this._handleSecoCheckList()}"
            .disabled="${!this.adresseId}"
          ></vzavg-checklist>
          <isceco-button
            id="nextAddress"
            icon="chevron right"
            variant="primary"
            @click="${_ => navigate({
              to: geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash,
              id: this.gesuchId
            })}"
          ></isceco-button>
        </div>
        <div slot="right" class="title-content">
          <isceco-button
            id="adresse-add-adresse-btn"
            title="${this.i18n.translate('geschaeftsvorfall.adresse.button.create')}"
            icon="plus"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.adresseEdit.hash, id: this.gesuchId})}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="adresse-speichern"
            variant="primary"
            icon="save"
            @click="${_ => this.save()}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <vzavg-checklist-ok
            id="checklistOk"
            name="checklistOk"
            .disabled="${this.backendData.isChecklistOverridden}"
            .hidden="${window.betrieb$?.getValue()?.bewilligungspflicht === 'no'}"
            .clickCallback="${_ => this._handleChecklistOverride()}"
          ></vzavg-checklist-ok>
        </div>
      </vzavg-title>
      <isceco-form id="adresse-form">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>

      ${this._renderDialogs()}
    `
  }

  _renderFormFields() {
    const adresseTypElement = document.getElementById('adresseTypCode');
    return html`
      <div class="flex">
        <div class="flex-column">
          <isceco-dropdown
            style="margin-bottom: 5px;"
            id="adresseTypCode"
            name="adresseTypCode"
            required
            value="${this.backendData.adresseTypCode}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.typ')} *"
            .items="${this.adressTypen}"
            @change="${e => this._updateAdressType(e)}"
            ?disabled="${this._isTypDisabled()}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-dropdown>
          ${this.isAddressAlreadyExist ?
            html`
              <span>
              <i
                class="exclamation triangle icon orange"
              ></i>${this.i18n.translate('geschaeftsvorfall.adresse.typ.already.used')}
              </span>
            ` : html``}
          <isceco-switch
            style="display: ${this.backendData.adresseTypCode === 'HS' ? '' : 'none'}"
            id="rsVonHsUebernehmen"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.rSVonHSSwitch')}"
            .value="${this.backendData.rsVonHsUebernehmen}"
            @change="${e => this._updateValue(e)}"
            ?disabled="${this._isRsHsAndHsAlreadyExists(this.existingAdressen)}"
          ></isceco-switch>
          <div id="uid-group-fields" class="flex" style="gap: 0.3em">
            <vzavg-uid-search-button
              @change="${e => this._setBetriebSearchData(e.detail)}"
              .disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              value="${this.backendData.unternRegisterId}"
              id="unternRegisterId"
              name="unternRegisterId"
              ?required="${this._isUIDRequired()}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              .label="${this._getUIDLabel(adresseTypElement)}"></vzavg-uid-search-button>
          </div>
          <vzavg-error-msg
            id="uid-error-msg"
            msg="${this.uidErrorMessage}"
          ></vzavg-error-msg>
          <isceco-text-input
            id="firmenbezeichnung"
            name="firmenbezeichnung"
            value="${this.backendData.firmenbezeichnung}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.firmenbezeichnung')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-text-input>
          <isceco-text-input
            id="strasse"
            name="strasse"
            value="${this.backendData.strasse}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.strasse')} *"
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></isceco-text-input>
          <isceco-text-input
            id="adresszusatz"
            name="adresszusatz"
            value="${this.backendData.adresszusatz}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.adresszusatz')}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            @change="${e => this._updateValue(e)}"
          ></isceco-text-input>
          <isceco-text-input
            id="postfach"
            name="postfach"
            value="${this.backendData.postfach}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.postfach')}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-text-input>
          <vzavg-plz-ort-input
            id="plzOrtAdresse"
            name="plzOrtAdresse"
            .i18n="${this.i18n}"
            .ortValue="${this.backendData.ort}"
            .plzValue="${this.backendData.plz}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            @change="${e => this._updateValue(e)}"
            required
          ></vzavg-plz-ort-input>
        </div>
        <div class="flex-column">
          <isceco-text-input
            id="telefon"
            name="telefon"
            value="${this.backendData.telefon}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.telefon')}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            pattern="${VzavgPattern.simpleTelefonPattern}"
            pattern-error-text="${this.i18n.translate('geschaeftsvorfall.adresse.telefon.pattern.error')}"
            placeholder="${AdresseEdit.TELEFON_DEFAULT}"
            @change="${e => this._updateValue(e)}"
          ></isceco-text-input>
          <isceco-text-input
            id="email"
            name="email"
            value="${this.backendData.email}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.email')}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            pattern="${VzavgPattern.emailPattern}"
            pattern-error-text="${this.i18n.translate('geschaeftsvorfall.adresse.email.pattern.error')}"
            @change="${e => this._updateValue(e)}"
          ></isceco-text-input>
          <isceco-text-input
            id="web"
            name="web"
            value="${this.backendData.web}"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.web')}"
            placeholder="www."
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            pattern="${VzavgPattern.urlPattern}"
            pattern-error-text="${this.i18n.translate('geschaeftsvorfall.adresse.web.pattern.error')}"
            @change="${e => this._updateValue(e)}"
          ></isceco-text-input>
          <vzavg-date-input-with-warning
            id="von"
            name="von"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.von')} *"
            value="${this.backendData.von}"
            .warningMinDate="${this._getDateOneYearAgo()}"
            .warningMinDescription="${this.i18n.translate('geschaeftsvorfall.adresse.dialog.von.datum.check.older.description')}"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></vzavg-date-input-with-warning>
          <vzavg-date-input-with-warning
            id="bis"
            name="bis"
            label="${this.i18n.translate('geschaeftsvorfall.adresse.bis')}"
            min="${this._updateBisMinDate(this.backendData.von)}"
            .warningMinDate="${this._getDateOneYearAgo()}"
            .warningMaxDate="${this._getDateOnYearInTheFuture()}"
            .warningMinDescription="${this.i18n.translate('geschaeftsvorfall.adresse.dialog.bis.datum.check.older.description')}"
            .warningMaxDescription="${this.i18n.translate('geschaeftsvorfall.adresse.dialog.bis.datum.check.younger.description')}"
            @change="${e => this._updateValue(e)}"
            value="${this.backendData.bis}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></vzavg-date-input-with-warning>
        </div>
      </div>
    `
  }

  _updateAdressType(e) {
    const selectedAdresse = e.detail.value
    if (selectedAdresse === 'GS') {
      this.backendData.firmenbezeichnung = window.betrieb$.getValue().firmenbezeichnung;
    }
    this.isAddressAlreadyExist = this.existingAdressTypCodes.includes(selectedAdresse);
    this._updateAndRender(e)
  }

  _updateAndRender(e) {
    this._updateValue(e)
    document.getElementById('rsVonHsUebernehmen').style.display = e.detail.value === 'HS' ? '' : 'none'
    this.render()
  }


  _getUIDLabel() {
    return this.i18n.translate('geschaeftsvorfall.adresse.untern.register.id') + (this._isUIDRequired() ? ' *' : '')
  }

  _isUIDRequired() {
    return this.backendData.adresseTypCode === 'HS'
  }

  _renderDialogs() {
    return html`
      <vzavg-gesuchadresse-hsrs-dialog
        id="adr-rs-hs-dialog"
        .adresse="${this.rsProposal}"
        .i18n="${this.i18n}"
      ></vzavg-gesuchadresse-hsrs-dialog>

      <vzavg-gesuchadresse-diff-dialog
        id="gesuchadresse-diff-dialog"
        .i18n="${this.i18n}"
      ></vzavg-gesuchadresse-diff-dialog>
    `
  }

  _getDateOneYearAgo() {
    const currentDate = new Date()
    const oneYearAgo = new Date(currentDate)
    // Subtract one year from the current date
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
    return oneYearAgo
  }

  _getDateOnYearInTheFuture() {
    const currentDate = new Date();
    const oneYearInFuture = new Date(currentDate);

    // Add one year to the current date
    oneYearInFuture.setFullYear(currentDate.getFullYear() + 1);

    return oneYearInFuture;
  }

  async _getAdresseAndRender() {

    if (!this.adresseId) {
      this._initBackendData();
      this.render()
      return
    }

    this.gesuchService.read(this.gesuchId, `adresse/${this.adresseId}`)
      .then(gesuchAdresseResponse => {
        if (isNoDataFound(gesuchAdresseResponse)) {
          this._initBackendData();
          this.render()
          return
        }

        // object exists already
        this.backendData = gesuchAdresseResponse
        this.backendData.typ = this._getTypFromCode(this.backendData.typCode)
        this.backendData.kantonCheckList = []
        this.backendData.secoCheckList = []
        this.backendData.isChecklistOverridden = AuthorizationFilter.hasKantonRole() ? this.backendData.isKantonChecklistOverridden : this.backendData.isSecoChecklistOverridden

        this.gesuchService.readAdresseChecklist(this.gesuchId, this.adresseId)
          .then(checklistResponse => {
            this._processChecklistResponse(checklistResponse)
            this.render()
          })
      })
      .catch(_ => {
        showAlert('', this.i18n.translate('geschaeftsvorfall.adresse.edit.error'))
        this.render()
      })
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.von = new Date().toISOString().split('T')[0]
    this.backendData.rsVonHsUebernehmen = false
    this.backendData.kantonCheckList = []
    this.backendData.secoCheckList = []
    this.backendData.telefon = ''
    this.backendData.isKantonChecklistOverridden = false
    this.backendData.isSecoChecklistOverridden = false
    this.backendData.isChecklistOverridden = false
  }

  _handleKantonCheckList() {
    this.kantonCheckListChecks = document.getElementById('kantonCheckListChecks').getValue()
    this.save()
  }

  _handleSecoCheckList() {
    this.secoCheckListChecks = document.getElementById('secoCheckListChecks').getValue()
    this.save()
  }

  async save() {
    this._getSpeichernBtn().text = this.i18n.translate('geschaeftsvorfall.adresse.button.send.wait')
    const form = document.getElementById('adresse-form');

    if (!form.validate()) {
      const telefonInput = document.getElementById("telefon");
      const telefonInputCanBeEmpty = !this._isTelOrEmailRequired() && !telefonInput.validate() && telefonInput.getValue() !== AdresseEdit.TELEFON_DEFAULT
      if (telefonInputCanBeEmpty || telefonInput.validate() || this._isTelOrEmailRequired()) {
        this._getSpeichernBtn().text = ''
        return Promise.resolve(false)
      }
    }

    this.formDataToSave = this._extractDataFromForm(form);
    if (this.formDataToSave.adresseTypCode === 'HS') {
      return this._updateAdresseFromUidIfNecessary()
        .then(_ => this._copyRevisionstelleFromHauptsitz())
        .then(_ => {
          this._getSpeichernBtn().text = ''
          return this._createOrUpdate(this.formDataToSave)
        }).catch(_ => Promise.resolve(false))
    } else {
      return this._removeUidIfNecessary()
        .then(_ => {
          this._getSpeichernBtn().text = ''
          return this._createOrUpdate(this.formDataToSave)
        })
    }
  }

  _extractDataFromForm(form) {
    const formData = form.getValues()
    formData.gesuchId = this.gesuchId
    formData.adresseId = this.adresseId
    formData.adresseTypCode = document.querySelector(AdresseEdit.ADRESSE_TYPE_CODE_FIELD_ID).getValue()
    formData.unternRegisterId = document.querySelector(AdresseEdit.UID_FIELD_ID).getValue()
    formData.plz = formData.plzOrtAdresse?.plz
    formData.ort = formData.plzOrtAdresse?.ortBez
    formData.telefon = formData.telefon === AdresseEdit.TELEFON_DEFAULT ? undefined : formData.telefon
    formData.kantonCheckListChecks = this.kantonCheckListChecks
    formData.secoCheckListChecks = this.secoCheckListChecks
    formData.isKantonChecklistOverridden = this.backendData.isKantonChecklistOverridden
    formData.isSecoChecklistOverridden = this.backendData.isSecoChecklistOverridden
    formData.von = this.backendData?.von
    formData.bis = this.backendData?.bis
    return formData
  }

  _removeUidIfNecessary() {
    if (this.formDataToSave.unternRegisterId) {
      return this.searchService.searchByUid(this.formDataToSave.unternRegisterId)
        .then(newAdresse => {
          const ourAdresse = this._convertAdresseForDiff(this.formDataToSave)
          const officialAdresse = this._convertSearchResultAdresseForDiff(newAdresse)
          const [hasChangedFields] = DataHelper.compareData(ourAdresse, officialAdresse)
          if (hasChangedFields) {
            this.formDataToSave.unternRegisterId = null
            return Promise.resolve()
          } else {
            return Promise.resolve()
          }
        })
    } else {
      return Promise.resolve()
    }
  }

  _updateAdresseFromUidIfNecessary() {
    return this.searchService.searchByUid(this.formDataToSave.unternRegisterId)
      .then(newAdresse => {
        const ourAdresse = this._convertAdresseForDiff(this.formDataToSave)
        const officialAdresse = this._convertSearchResultAdresseForDiff(newAdresse)
        const [hasChangedFields, changedFields] = DataHelper.compareData(ourAdresse, officialAdresse)

        if (hasChangedFields) {
          const dialog = document.querySelector('#gesuchadresse-diff-dialog')
          dialog.ourGesuchAdresse = ourAdresse
          dialog.officialGesuchAdresse = officialAdresse
          dialog.changedFields = changedFields

          return new Promise((resolve, reject) => {
            dialog.callback = diffResult => {
              if (!diffResult.confirmed) {
                this._getSpeichernBtn().text = ''
              }

              if (diffResult.confirmed && diffResult.overwrite) {
                this.formDataToSave.firmenbezeichnung = diffResult.newAdresse.firmenbezeichnung
                this.formDataToSave.strasse = diffResult.newAdresse.strasse
                this.formDataToSave.adresszusatz = diffResult.newAdresse.adresszusatz
                this.formDataToSave.postfach = diffResult.newAdresse.postfach
                this.formDataToSave.plz = diffResult.newAdresse.plz
                this.formDataToSave.ort = diffResult.newAdresse.ort
                resolve()
              }
              diffResult.confirmed ? resolve() : reject()

            }
            dialog.shadowRoot
              .querySelector('#isceco-diff-dialog')
              .removeAttribute('hidden')
          })
        } else {
          return Promise.resolve()
        }
      })
  }

  _copyRevisionstelleFromHauptsitz() {
    if (this.backendData.rsVonHsUebernehmen && this.formDataToSave.adresseTypCode === 'HS') {
      return this.gesuchService.readHsrsProposal(this.gesuchId, this.formDataToSave.unternRegisterId)
        .then(rsAdresse => {
          if (isObjectEmpty(rsAdresse)) {
            return Promise.resolve()
          } else {
            return new Promise(resolve => {
              const dialog = document.querySelector('#adr-rs-hs-dialog')
              dialog.adresse = rsAdresse
              dialog.callback = result => {
                this.formDataToSave.rsVonHsUebernehmen = result.confirmed
                resolve()
              }

              dialog.shadowRoot
                .querySelector('#isceco-rs-von-hs-dialog')
                .removeAttribute('hidden')
            })
          }
        })
    } else {
      return Promise.resolve()
    }
  }

  _createOrUpdate(formDatas) {
    formDatas.id = this.adresseId
    const now = new Date()
    const bis = formDatas.bis !== null && formDatas.bis !== undefined ? new Date(formDatas.bis) : new Date()
    bis.setHours(now.getHours())
    bis.setMinutes(now.getMinutes())
    bis.setSeconds(now.getSeconds())
    const isAddressValid = (bis.getTime() + 5000) >= now.getTime()

    if (formDatas.adresseId) {
      return this._update(formDatas, isAddressValid);
    } else {
      return this._create(formDatas, isAddressValid);
    }
  }

  _update(formDatas, isAddressValid) {
    return this.gesuchService.update(formDatas, `${this.gesuchId}/adresse`)
      .then(_ => {
        this._showAlertAndNavigate(isAddressValid, false)
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
        return true
      }).catch(_ => false)
  }

  _create(formDatas, isAddressValid) {
    return this.gesuchService.create(formDatas, `${this.gesuchId}/adresse`)
      .then(response => {
        const location = response.headers.get('Location')
        this.adresseId = location.slice(location.lastIndexOf('/') + 1)
        this._showAlertAndNavigate(isAddressValid)
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
        return true
      }).catch(_ => false)
  }

  _showAlertAndNavigate(isAddressValid, isCreate = true) {
    const messageKeyTitle = isCreate ? 'geschaeftsvorfall.adresse.create.success.title' : 'geschaeftsvorfall.adresse.update.success.title'
    const messageKey = isCreate ? 'geschaeftsvorfall.adresse.create.success.message' : 'geschaeftsvorfall.adresse.update.success.message'
    showAlert(this.i18n.translate(messageKeyTitle), this.i18n.translate(messageKey), 'success')

    if (isAddressValid) {
      // navigate to refresh the tab statuses (handled in GeschaeftsvorfallNavigation.js parent object)
      navigate({to: geschaeftsVorfallNavigationLinks.adresseEdit.hash, id: `${this.gesuchId}/${this.adresseId}`})
    } else {
      // navigate to Adresse List, because the current address is no more valid and refresh the tab statuses (handled in GeschaeftsvorfallNavigation.js parent object)
      navigate({to: geschaeftsVorfallNavigationLinks.adresse.hash, id: `${this.gesuchId}`})
    }
  }

  _backToAdressenList() {
    navigate({to: geschaeftsVorfallNavigationLinks.adresse.hash, id: this.gesuchId})
  }

  _closeConfirmChangeDialog(e) {
    e.target.hidden = true;
    if (e.detail.confirmed) {
      navigate({to: geschaeftsVorfallNavigationLinks.adresse.hash})
    }
  }

  _openSearchDialog() {
    // remove search params
    const dialog = document.querySelector('#betrieb-search-dialog')
    dialog.shadowRoot.querySelector('#betrieb-search-uid').value = ''
    dialog.shadowRoot.querySelector('#betrieb-search-name').value = ''
    dialog.shadowRoot.querySelector('#bur-search-errormsg').innerHTML = ''

    document.querySelector('#betrieb-search-dialog').shadowRoot
      .querySelector('#isceco-search-dialog')
      .removeAttribute('hidden')
  }

  _setBetriebSearchData = row => {
    this.backendData.unternRegisterId = row.uid
    this.backendData.firmenbezeichnung = row.name
    this.backendData.strasse = `${row.street} ${row.houseNumber}`
    this.backendData.plz = row.swissZipCode
    this.backendData.ort = row.town
    this.backendData.postfach = row.postfach
    this.isBetriebInfoUebernommen = true

    this.reload()
  }

  _getAdresseId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  async _prepareAdressTypen() {
    this.adressTypen = await this._loadAdresseTypenStammdaten();
    this.existingAdressen = await this.gesuchService.read(this.gesuchId, 'adresse');
    this.existingAdressTypCodes = this.existingAdressen.map(a => a.adresseTypCode)
  }

  async _loadAdresseTypenStammdaten() {
    const backendAdressTypen = await this.stammdatenService.getAdresseTypen()
    const frontendAdressTypen = []
    for (const i in backendAdressTypen) {
      frontendAdressTypen[i] = {  // NOSONAR
        value: backendAdressTypen[i].code,
        name: backendAdressTypen[i].bezeichnung ? backendAdressTypen[i].bezeichnung : ''
      }
    }
    return frontendAdressTypen
  }

  _isAlreadyExisting(adressTypCode, existingAdressen) {
    for (const existingAdresse of existingAdressen) {
      if (adressTypCode === existingAdresse.adresseTypCode && adressTypCode !== 'GS') {
        return true
      }
    }
    return false
  }

  _isRsHsAndHsAlreadyExists(existingAdressen) {
    return this._isAlreadyExisting('HSRS', existingAdressen);
  }

  _getTypFromCode(typeCode) {
    for (const adressTyp of this.adressTypen) {
      if (adressTyp.value === typeCode) {
        return adressTyp.name;
      }
    }
    return undefined
  }

  _convertAdresseForDiff(values) {
    return {
      uid: values.unternRegisterId ? values.unternRegisterId : null,
      firmenbezeichnung: values.firmenbezeichnung ? values.firmenbezeichnung : null,
      strasse: values.strasse ? values.strasse : null,
      adresszusatz: values.adresszusatz ? values.adresszusatz : null,
      postfach: values.postfach ? values.postfach : null,
      plz: values.plz ? values.plz : null,
      ort: values.ort ? values.ort : null
    }
  }

  _convertSearchResultAdresseForDiff(values) {
    return {
      uid: values.uid ? values.uid : null,
      firmenbezeichnung: values.name ? values.name : null,
      strasse: this._getStrasseFromUIDSearch(values),
      adresszusatz: values.adresszusatz ? values.adresszusatz : null,
      postfach: values.postfach ? values.postfach : null,
      plz: values.swissZipCode ? values.swissZipCode : null,
      ort: values.town ? values.town : null
    }
  }

  _getStrasseFromUIDSearch(values) {
    if (values.street && values.houseNumber){
      return `${values.street} ${values.houseNumber}`
    }
    if (values.street) {
      return values.street
    }
    if (values.houseNumber){
      return values.houseNumber
    }
    return null
  }

  _isTypDisabled() {
    if (this._getAdresseId()) {
      return true
    }
    return AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)
  }

  _getSpeichernBtn() {
    return document.getElementById(AdresseEdit.SPEICHERN_BTN_ID)
  }

  _updateValue(event, reload = false) {
    window.hasChanges = true;
    const key = event.target.id;
    if (key === 'plzOrtAdresse') {
      if (this.backendData['plz'] !== event.detail.plz || this.backendData['ort'] !== event.detail.ortBez) {
        this.backendData['plz'] = event.detail.plz
        this.backendData['ort'] = event.detail.ortBez
      }
    } else if (key === 'von' || key === 'bis') {
      if (this.backendData[key] !== event.detail) {
        this.backendData[key] = event.detail
      }
    } else {
      if (this.backendData[key] !== event.detail.value) {
        this.backendData[key] = event.detail.value
      }
    }

    if (reload) {
      this.reload();
    }
  }

  _updateBisMinDate(vonDatum) {
    const bisDatum = new Date(vonDatum)
    bisDatum.setDate(bisDatum.getDate() + 1);
    return VzavgFormation.formatDateToYYYYMMDD(bisDatum)
  }

  _processChecklistResponse(checkListsResponse) {
    if (checkListsResponse.checks) {
      for (const check of checkListsResponse.checks) {
        if (check.bereich === 'kanton') {
          this.backendData.kantonCheckList.push(check)
        } else {
          this.backendData.secoCheckList.push(check)
        }
      }
    }
  }

  _handlePreviousAdress() {
    navigate({
      to: geschaeftsVorfallNavigationLinks.adresseEdit.hash,
      id: `${this.gesuchId}/${this.backendData.previousAdresseId}`
    })
  }

  _handleNextAddress() {
    navigate({
      to: geschaeftsVorfallNavigationLinks.adresseEdit.hash,
      id: `${this.gesuchId}/${this.backendData.nextAdresseId}`
    })
  }

  _handleChecklistOverride() {
    if (AuthorizationFilter.hasKantonRole()) {
      this.backendData.isKantonChecklistOverridden = true
    } else {
      this.backendData.isSecoChecklistOverridden = true
    }
    this.backendData.isChecklistOverridden = true
    this.save()
  }
}
customElements.define('vzavg-geschaeftsvorfall-adresse-edit', AdresseEdit)
