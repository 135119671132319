import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import StammdatenService from '../../../../services/StammdatenService.js'

export default class VerantwortlichePFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    stammdatenService.getKantone()
      .then(kantone => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  get verantwortlichePStatus() {
    return [
      {name: this.i18n.translate('suchen.aktiv'), value: true},
      {name: this.i18n.translate('suchen.inaktiv'), value: false}
    ]
  }

  set values(val) {
    this._values = val
  }

  async connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown
          label="${this.i18n.translate('suchen.kanton')}"
          id="kanton"
          name="kanton"
          .items="${this.kantone}"
          .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.status')}"
          id="isActive"
          name="isActive"
          .items="${this.verantwortlichePStatus}"
          .value="${this.values?.isActive}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.verantwortlicheP.taetigVon')}"
          id="taetigVon"
          name="taetigVon"
          .value="${this.values?.taetigVon}"
          .max="${isEmpty(this.values?.taetigVon) ? '' : this.values?.taetigBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.verantwortlicheP.taetigBis')}"
          id="taetigBis"
          name="taetigBis"
          .value="${this.values?.taetigBis}"
          .min="${isEmpty(this.values?.taetigBis) ? '' : this.values?.taetigVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.verantwortlicheP.sperrdatumVon')}"
          id="sperrdatumVon"
          name="sperrdatumVon"
          .value="${this.values?.sperrdatumVon}"
          .max="${isEmpty(this.values?.sperrdatumVon) ? '' : this.values?.sperrdatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.verantwortlicheP.sperrdatumBis')}"
          id="sperrdatumBis"
          name="sperrdatumBis"
          .value="${this.values?.sperrdatumBis}"
          .min="${isEmpty(this.values?.sperrdatumBis) ? '' : this.values?.sperrdatumVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
      </div>
    `
  }

  _updateValue(event) {
    const key = event.target.id;
    if (key.includes('Von') || key.includes('Bis')) {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
        const date = new Date(event.detail.value)
        if (date?.getFullYear() > 1980) {
          this.reload()
        }
      }
    } else {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
      }
    }
  }

  get css() {
    return css`
      .filter-wrapper > * {
        min-width: 20em;
      }
    `
  }
}
customElements.define('vzavg-frontend-suche-verantwortliche-person-filter', VerantwortlichePFilter)
