import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import GesuchPendenzService from '../../../services/GesuchPendenzService'
import PendenzColumns from './PendenzColumns'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem'


export default class Pendenz extends WebComponent {

  static get EVENT_KEYS() {
    return {
      TRIGGER_PENDENZ_TABLE_RELOAD: 'geschaeftvorfall.pendnez.table.reload.event'
    }
  }

  constructor() {
    super()
    this.gesuchPendenzService = new GesuchPendenzService();
  }

  get css() {
    return css``
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Pendenz/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.pendenzListService = await this.gesuchPendenzService.getListService(this.gesuchId)

    this.columns = [
      PendenzColumns.EINGANGSDATUM_COLUMN(),
      PendenzColumns.BETREFF_COLUMN(),
      PendenzColumns.SENDER_COLUMN(),
      PendenzColumns.EMPFAENGER_COLUMN(),
      PendenzColumns.ERLEDIGT_AM_COLUMN(),
      PendenzColumns.CONTAIN_DOCS_COLUMN(),
      PendenzColumns.AKTIONEN_COLUMN({callback: this._rowActionMenu})
    ]

    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('geschaeftsvorfall.pendenzen.header')}">
        <isceco-button
          id="pendenz-add-btn"
          title="${this.i18n.translate("geschaeftsvorfall.pendenzen.button.create")}"
          icon="plus"
          variant="primary"
          size="small"
          @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.pendenzEdit.hash, id: this.gesuchId})}"
          ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
        ></isceco-button>
      </isceco-title>

      ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    const rowVariant = {
      get: row => {
        if (!row.prio) {
          return 'eingangsdatum'
        }
        return ''
      }
    }
    return this.columns ? html`
      <isceco-list
        id="pendenz-list"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .rowVariant="${rowVariant}"
        .listService="${this.pendenzListService}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
        update-event="${Pendenz.EVENT_KEYS.TRIGGER_PENDENZ_TABLE_RELOAD}"
      ></isceco-list>
    ` : html``
  }

  _reloadList() {
    send(Pendenz.EVENT_KEYS.TRIGGER_PENDENZ_TABLE_RELOAD)
    this.reload()
  }

  _rowActionMenu = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.pendenzEdit.hash, id: `${this.gesuchId}/${row.id}`})
    }
  }
}
customElements.define('vzavg-frontend-geschaeftsvorfall-pendenz', Pendenz)
