import {css, html} from '@isceco/widget-library2/external/lit'
import {classMap} from '@isceco/widget-library2/external/lit-html/directives/class-map'
import VzavgFormation from '../../common/VzavgFormation.js'
import TranslationElement from '../../common/TranslationElement.js'
import '../Betrieb/BetriebSearchDialog.js'


export default class UidSearchButton extends TranslationElement {

  static UID_BUTTON_WRAPPER = 'uid_button-wrapper'
  static UID_ADD_BUTTON = 'add_uid_button'
  static UID_SEARCH_DIALOG_ID = 'uid-search-dialog'
  static UID_FIELD_ID = 'unternRegisterId'

  static styles = css`
    .input-wrapper {
      display: flex;
      gap: 0.3em;
    }

    .uid_button-wrapper.error isceco-button {
      outline: 2px solid var(--isceco-color-red-500);
      border-radius: 4px;
    }

    .uid_button-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }

    .uid_button-wrapper label {
      font-weight: bold;
      color: var(--isceco-color-black-500);
    }

    .uid-button-error-wrapper {
      display: flex;
      gap: 4px;
    }

    .uid_label-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .burwebSearchIcon {
      font-size: 1.5em !important;
      max-width: 2em;
      align-self: flex-end;
      padding-bottom: 0.5em;
      color: var(--isceco-color-blue-500);
      cursor: pointer;
    }
  `;

  static get properties() {
    return {
      label: {type: String},
      disabled: {type: Boolean},
      value: {type: String},
      readonly: {type: Boolean},
      required: {type: Boolean},
      kanton: {type: String}
    }
  }

  get formattedValue() {
    return VzavgFormation.formatUID(this.value)
  }

  get translationFile() {
    return './components/UidSearchButton/i18n.json'
  }

  constructor() {
    super();
    this.inValid = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('required') && !this.required) {
      this.validate()
      this.requestUpdate()
    }
  }

  get classes() {
    return {error: this.inValid, [UidSearchButton.UID_BUTTON_WRAPPER]: true}
  }

  render() {
    return this.translationLoaded ? html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      ${this._renderUID()}
      <vzavg-betrieb-search-dialog
        .id="${UidSearchButton.UID_SEARCH_DIALOG_ID}"
        .callback="${this._betriebSelected}"
        .i18n="${this.i18n}"
        kanton="${this.kanton}"
      ></vzavg-betrieb-search-dialog>
    ` : html``
  }

  /**
   * implementation of getValue for the FormElement
   * @returns {String}
   */
  getValue = () => this.value

  /**
   * validates the field
   * @returns {boolean}
   */
  validate = () => {
    this.inValid = false;
    if (this.readonly) {
      return true
    }
    if (this.required && !this.value.length) {
      const uidGroupFields = this.shadowRoot.querySelector(`.${UidSearchButton.UID_BUTTON_WRAPPER}`)
      uidGroupFields.classList.add('error')
      this.inValid = true
      this.requestUpdate()
      return false;
    }
    return true;
  }

  _renderUID() {
    return VzavgFormation.formatUID(this.value) ? this._renderUIDInformation() : this._renderUIDButton()
  }

  _renderUIDButton() {
    return html`
      <div class="${classMap(this.classes)}">
        <label for="getUid">${this.label}</label>
        <div class="uid-button-error-wrapper">
          <isceco-button
            .text="${this.i18n.translate('uid.search.button.text')}" icon="search"
            variant="primary"
            .id="${UidSearchButton.UID_ADD_BUTTON}"
            @click="${_ => this._openSearchDialog()}"
            ?disabled="${this.disabled || this.readonly}"
          ></isceco-button>
        </div>
        ${this.inValid ? html`
          <vzavg-error-msg
            id="uid-error-msg"
            msg="${this.i18n.translate('error.cannot.be.empty')}"
          ></vzavg-error-msg>` : html``}
      </div>`
  }

  _renderUIDInformation() {
    return html`
      <div class="uid_label-wrapper">
        <isceco-text-input
          id="${UidSearchButton.UID_FIELD_ID}"
          name="${UidSearchButton.UID_FIELD_ID}"
          value="${this.formattedValue}"
          label="${this.label}"
          readonly
        ></isceco-text-input>
        ${this.readonly ? html`` : html`
          <i id="searchIcon"
             class="search icon burwebSearchIcon"
             @click="${_ => this._openSearchDialog()}"
          ></i>
        `}
      </div>
    `
  }

  _openSearchDialog() {
    // remove search params
    const dialog = this.shadowRoot.getElementById(UidSearchButton.UID_SEARCH_DIALOG_ID)
    dialog.shadowRoot.querySelector('#betrieb-search-uid').value = ''
    dialog.shadowRoot.querySelector('#betrieb-search-name').value = ''
    dialog.shadowRoot.querySelector('#bur-search-errormsg').innerHTML = ''

    dialog.shadowRoot
      .querySelector('#isceco-search-dialog')
      .removeAttribute('hidden')

    const betriebNameInputField = this.shadowRoot.querySelector('#uid-search-dialog').shadowRoot.getElementById('betrieb-search-name').shadowRoot.getElementById('isceco-text-input')
    betriebNameInputField.focus()
  }

  _betriebSelected = betrieb => {
    send('change', betrieb, this)
  }
}
customElements.define('vzavg-uid-search-button', UidSearchButton);
