import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import StammdatenService from '../../../../services/StammdatenService.js'

export default class KautionFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    Promise.all([stammdatenService.getOrganisation(), stammdatenService.getKautionsart()])
      .then(([kantone, kautionsarten]) => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
        this.kautionsarten = kautionsarten.map(kaution => ({value: kaution.code, name: kaution.bezeichnung}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get betriebStatus() {
    return [
      {name: this.i18n.translate('suchen.aktiv'), value: true},
      {name: this.i18n.translate('suchen.inaktiv'), value: false}
    ]
  }

  get values() {
    return this._values
  }

  set values(val) {
    this._values = val
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown
          label="${this.i18n.translate('suchen.kanton')}" id="kanton" name="kanton"
          .items="${this.kantone}"
          .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betriebStatus')}" id="betriebIsActive"
          name="betriebIsActive"
          .items="${this.betriebStatus}"
          .value="${this.values?.betriebIsActive}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.kaution.kautionsArt')}" id="kautionsArt"
          name="kautionsArt"
          .items="${this.kautionsarten}"
          .value="${this.values?.kautionsArt}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.kaution.kautionsDatumVon')}"
          id="kautionsDatumVon"
          name="kautionsDatumVon"
          .value="${this.values?.kautionsDatumVon}"
          max="${isEmpty(this.values?.kautionsDatumVon) ? '' : this.values?.kautionsDatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.kaution.kautionsDatumBis')}"
          id="kautionsDatumBis"
          name="kautionsDatumBis"
          .value="${this.values?.kautionsDatumBis}"
          min="${isEmpty(this.values?.kautionsDatumBis) ? '' : this.values?.kautionsDatumVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.kaution.befristetBis')}"
          id="befristetBis"
          name="befristetBis"
          .value="${this.values?.befristetBis}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.kaution.blockiertBis')}"
          id="blockiertBis"
          name="blockiertBis"
          .value="${this.values?.blockiertBis}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.kaution.gesperrtBis')}"
          id="gesperrtBis"
          name="gesperrtBis"
          .value="${this.values?.gesperrtBis}"
        ></isceco-date-input>

        <isceco-text-input
          type="number" label="${this.i18n.translate('suchen.kaution.kautionsBetragVon')}"
          id="kautionsBetragVon" name="kautionsBetragVon"
          .value="${this.values?.kautionsBetragVon}"
        ></isceco-text-input>
        <isceco-text-input
          type="number" label="${this.i18n.translate('suchen.kaution.kautionsBetragBis')}"
          id="kautionsBetragBis" name="kautionsBetragBis"
          .value="${this.values?.kautionsBetragBis}"
        ></isceco-text-input>
      </div>
    `
  }

  _updateValue(event) {
    const key = event.target.id;
    if (key.includes('Von') || key.includes('Bis')) {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
        const date = new Date(event.detail.value)
        if (date?.getFullYear() > 1980) {
          this.reload()
        }
      }
    } else {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
      }
    }
  }

  get css() {
    return css`
      .filter-wrapper > * {
        min-width: 20em;
      }
    `
  }
}
customElements.define('vzavg-frontend-suche-kaution-filter', KautionFilter)
