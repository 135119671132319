import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import CockpitStatistikdatenService from '../../../services/CockpitStatistikdatenService.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall/Geschaeftsvorfall.js'
import Cockpit from '../Cockpit.js'
import I18n from '../../../i18n.js'
import {List} from '@isceco/widget-library2/basic-elements/List/List.js'
import CockpitStatistikdatenColumns from './CockpitStatistikdatenColumns.js'
import VzavgListService from '../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import ExportService from '../../../services/ExportService.js'


const TRANSLATION_KEY_COCKPIT_CARDS_SHOW = 'cockpit.cards.show'

export default class CockpitStatistikdaten extends WebComponent {

  constructor() {
    super()

    this.service = new CockpitStatistikdatenService()
    this.exportService = new ExportService(`cockpitStatistikdaten/exportStatistikdatenMissing`)
  }

  get css() {
    return css``
  }

  get translationFile() {
    return './views/Cockpit/CockpitStatistikdaten/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()

    this.backendData = {
      statisticYear: 0,
      totalCompanies: 0,
      missingStatistikdatenCompanies: 0,
      keineRevisionstelleTotal: 0
    }

    this._refresh = () => {
      send(Cockpit.EVENT_KEYS.RELOAD_TRIGGERED)
    }

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.addEventListener(List.EVENT_KEYS.LIST_UPDATED, this._listUpdated)

    this.columnsFehlendeStatistikDaten = new CockpitStatistikdatenColumns(this._openStat).columns
    this.columnsKeineRevisionstelle = new CockpitStatistikdatenColumns(this._openAdresse).columns

    Promise.all([
      this.service.getBackendListUrl('statistikdatenMissingList').then(url => this.statistikdatenMissingListUrl = url),
      this.service.getBackendListUrl('keineRevisionstelle').then(url => this.statistikdatenKeineRevisionstelleUrl = url),
      this.service.readHeader().then(data => this.backendData = data)
    ]).then(_ => {
      this.render()
    })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.removeEventListener(List.EVENT_KEYS.LIST_UPDATED, this._listUpdated)
  }

  getTemplate() {
    const statTranslation = this.i18n.translate('cockpit.statistikdaten.header')
    return html`
      <isceco-title text="${statTranslation}"></isceco-title>
      <p>${this.i18n.translate('cockpit.statistikdaten.erhebung.datum')}</p>
      <div class="cardContainer">
        ${AuthorizationFilter.hasKantonRole() ?
      html`
            <isceco-card
              id="missing-stats"
              class="card"
              text="${this.i18n.translate('cockpit.statistikdaten.fehlende.statistikdaten')} ${this.backendData.missingStatistikdatenCompanies}/${this.backendData.totalCompanies}">
              <p>${this.i18n.translate('cockpit.statistikdaten.berichtsjahr')} ${this.backendData.statisticYear}</p>
              <isceco-link
                id="link-missing-stat"
                icon="hand point down"
                text="${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                @click="${_ => this._showHideList('statMissingListDiv')}">
              </isceco-link>
              <div id="statMissingListDiv" hidden>
                <isceco-list
                  id="stat-missing-betrieb-list"
                  max="10"
                  order="asc"
                  .listService="${new VzavgListService(this.statistikdatenMissingListUrl)}"
                  identifier="statMissingList"
                  update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
                  .columns="${this.columnsFehlendeStatistikDaten}"
                  .i18n="${this.i18n}"
                  .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
                </isceco-list>
                <div style="display: flex; justify-content: flex-end">
                  <isceco-button
                    id="download-statistikdaten-missing"
                    icon="download"
                    @click="${() => this._openCvsExportDialog()}"
                  ></isceco-button>
                </div>
              </div>
            </isceco-card>
          ` : html``}
        ${AuthorizationFilter.hasKantonRole() ?
      html`
            <isceco-card
              id="no-revisionstelle"
              class="card"
              text="${this.i18n.translate('cockpit.statistikdaten.keine.revisionstelle')} ${this.backendData.keineRevisionstelleTotal}">
              <isceco-link
                id="link-missing-stat"
                icon="hand point down"
                text="${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                @click="${_ => this._showHideList('statKeineRevisionstelleListDiv')}">
              </isceco-link>
              <div id="statKeineRevisionstelleListDiv" hidden>
                <isceco-list
                  id="stat-keine-revisionstelle-betrieb-list"
                  max="10"
                  order="asc"
                  .listService="${new VzavgListService(this.statistikdatenKeineRevisionstelleUrl)}"
                  identifier="statNoRevision"
                  update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
                  .columns="${this.columnsKeineRevisionstelle}"
                  .i18n="${this.i18n}"
                  .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
                </isceco-list>
              </div>
            </isceco-card>
          ` : html``}
        <isceco-card
          class="card"
          text="${this.i18n.translate('cockpit.statistikdaten.kein.portalzugang')}">
          <p>content</p>
          <a href="#">${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}</a>
        </isceco-card>
        <isceco-card
          class="card"
          text="${this.i18n.translate('cockpit.statistikdaten.nicht.zustellbare.email')}">
          <p>content</p>
          <a href="#">${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}</a>
        </isceco-card>
        ${this.isSeco
      ? html`
            <isceco-card
              class="card"
              text="${this.i18n.translate('cockpit.statistikdaten.erhebung.abschlissen')}">
              <p>content</p>
              <a href="${'#' + geschaeftsVorfallNavigationLinks.gesuch.hash}">
                ${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}
              </a>
            </isceco-card>
          `
      : html``}
      </div>
      <isceco-title text=${this.i18n.translate('cockpit.statistikdaten.header.fortschritt.kantone')}></isceco-title>
      <div class="cardContainer">
        <isceco-card
          class="card"
          text="${this.i18n.translate('cockpit.statistikdaten.fortschritt.kantone.total')}">
          <p>content</p>
          <a href="#">${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}</a>
        </isceco-card>
        <isceco-card
          class="card"
          text="${statTranslation}">
          <p>content</p>
          <a href="#">${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}</a>
        </isceco-card>
        <isceco-card
          class="card"
          text="${statTranslation}">
          <p>content</p>
          <a href="#">${this.i18n.translate(TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}</a>
        </isceco-card>
      </div>
      <isceco-dialog
        id="cvs-export-dialog"
        hidden
        header="${this.i18n.translate('cockpit.statistikdaten.missing.export.header')}"
        description="${this.i18n.translate('cockpit.statistikdaten.missing.export.description')}"
        confirm-button="${this.i18n.translate('cockpit.statistikdaten.missing.export.confirm')}"
        Cancel-button="${this.i18n.translate('cockpit.statistikdaten.missing.export.cancel')}"
        @submit="${e => this._exportCsv(e)}">
        <div style="display: flex; align-items: center;gap: 0.1em">
          <isceco-text-input
            id="save-filename-id"
            class="save-dialog-input"
            maxlength="30"
            style="flex: 1"
          ></isceco-text-input>
          <span>.csv</span>
        </div>
      </isceco-dialog>
    `
  }

  _showHideList(id) {
    if (document.getElementById(id).getAttribute('hidden') === '') {
      document.getElementById(id).removeAttribute('hidden')
    } else {
      document.getElementById(id).setAttribute('hidden', '')
    }
  }

  _openCvsExportDialog() {
    const dialog = document.querySelector('#cvs-export-dialog')
    dialog.removeAttribute('hidden')
  }

  _exportCsv(e) {
    if (e.detail.confirmed) {
      const filename = toValidFilename(e.target.querySelector('#save-filename-id').getValue())
      this.exportService.getExport(filename, window.location.hash.split('?').pop())
        .then(response => response.blob())
        .then(blob => download(`${filename}.csv`, blob));
    }
    e.target.setAttribute('hidden', '')
  }

  _openStat = (row, _) => {
    const to = `#${geschaeftsVorfallNavigationLinks.statistikDaten.hash}`
    const id = row.gesuchId
    window.open(constructURL({to, id}), "_blank");
  }

  _openAdresse = (row, _) => {
    const to = `#${geschaeftsVorfallNavigationLinks.adresse.hash}`
    const id = row.gesuchId
    window.open(constructURL({to, id}), "_blank");
  }
}
customElements.define('vzavg-cockpit-statistikdaten', CockpitStatistikdaten)
