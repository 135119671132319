import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'

export default class BetriebsartDropdown extends LitElement {
  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGER: 'betriebsart-reload-triggered'
    }
  }

  static get properties() {
    return {
      value: {type: String},
      items: {type: Array},
      i18n: {type: Object},
      readonly : {type: Boolean},
      placeholder: {type: String}
    }
  }

  static get styles() {
    return css`
    `
  }

  constructor() {
    super();
    this.code = ''
  }

  connectedCallback() {
    super.connectedCallback();

    this._refresh = e => {
      this.value = e.detail
      this.requestUpdate()
    }
    document.addEventListener(BetriebsartDropdown.EVENT_KEYS.RELOAD_TRIGGER, this._refresh)
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(BetriebsartDropdown.EVENT_KEYS.RELOAD_TRIGGER, this._refresh)
  }

  render(){
    return html`
      <isceco-dropdown
        id="codeBetriebsart"
        name="codeBetriebsart"
        value="${this.value}"
        label="${this.i18n.translate('geschaeftsvorfall.gesuch.betriebsart')}"
        placeholder="${this.placeholder}"
        .items="${this.items}"
        @click="${e => e.stopPropagation()}"
        ?readonly="${this.readonly}"
      ></isceco-dropdown>
    `
  }

  getValue() {
    return this.shadowRoot.getElementById('codeBetriebsart').getValue()
  }

  validate() {
    return true
  }

}
customElements.define('betriebsart-dropdown', BetriebsartDropdown)
