import WebComponent from '../../../WebComponent.js'
import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Filter/SingleselectFilter.js'
import StatistikOverviewColumns from './StatistikOverviewColumns.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import StatistikOverviewService from '../../../services/StatistikOverviewService.js'
import '../../../components/Statistik/YearFilter.js'
import {List} from '@isceco/widget-library2/basic-elements/List/List.js'
import StammdatenService from '../../../services/StammdatenService.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall/Geschaeftsvorfall.js'

export default class FreigebenPruefen extends WebComponent {

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: "vzavg-statistics-overview-reload-triggered"
    };
  }

  get css() {
    return css`
      .wrapper {
        display: flex;
        flex-direction: column;
      }

      .button-wrapper {
        margin-left: auto;
      }

      .filters {
        margin-top: 1em;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    `
  }

  constructor() {
    super()
    this.statistikOverviewService = new StatistikOverviewService()
    //this.actualYear = new Date().getFullYear()
    this.actualYear = 2023 // see ticket #3354, this is the default year until 'Statistikdaten konsolidieren' is implemented
    this.selectedStatistikIds = new Set()
    this.stammdatenService = new StammdatenService()
  }

  get translationFile() {
    return './views/Statistikdaten/FreigebenPruefen/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback();

    this.columns = [
      StatistikOverviewColumns.SELECT_COLUMN({
        rowCallback: this._handleRowSelectionChange,
        headerCallback: this._toggleAllSelection
      }),
      StatistikOverviewColumns.KANTON_COLUMN(),
      StatistikOverviewColumns.BETRIEB_COLUMN(),
      StatistikOverviewColumns.STRASSE_COLUMN(),
      StatistikOverviewColumns.PLZ_COLUMN(),
      StatistikOverviewColumns.ORT_COLUMN(),
      StatistikOverviewColumns.EINGANGSDATUM_COLUMN(),
      StatistikOverviewColumns.MAX_DEVIATION_IN_PERCENT_COLUMN(),
      StatistikOverviewColumns.AKTIONEN_COLUMN({
        callback: this._rowAction
      })
    ]

    document.addEventListener(List.EVENT_KEYS.LIST_UPDATED, this._listUpdated)

    Promise.all(
      [
        this.statistikOverviewService.getBackendListUrl(),
        this.stammdatenService.getKantone()
      ]
    ).then(([resStatistikOverviewServiceUrl, kantone]) => {
      this.statistikOverviewUrl = resStatistikOverviewServiceUrl
      this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))

      this.render()
    })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(List.EVENT_KEYS.LIST_UPDATED, this._listUpdated)
  }

  getTemplate() {
    return html`
      <h1>${this.i18n.translate("statistikdaten.freigeben.title")}</h1>
      <div class="wrapper">
        <vzavg-year-filter key="year" default="${this.actualYear}"></vzavg-year-filter>
        <div class="filters">
          ${AuthorizationFilter.hasSecoRole() ?
            html`
              <isceco-singleselect-filter
                class="singleselect-filter"
                label="${this.i18n.translate('statistikdaten.filter.kanton')}"
                .items="${this.kantone}"
                key="kanton">
              </isceco-singleselect-filter>
            ` : html``
          }
          <isceco-singleselect-filter
            class="singleselect-filter"
            label="${this.i18n.translate('statistikdaten.filter.status')}"
            .items="${[
              {value: 'exist', name: this.i18n.translate("statistikdaten.filter.status.exist")},
              {value: 'empty', name: this.i18n.translate("statistikdaten.filter.status.empty")}
            ]}"
            key="status">
          </isceco-singleselect-filter>
        </div>
        <isceco-list
          id="statistikOverviewList"
          .defaultFilters="${{
            year: this.actualYear
          }}"
          url="${this.statistikOverviewUrl}"
          .columns="${this.columns}"
          .i18n="${this.i18n}"
          .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
          update-event=${FreigebenPruefen.EVENT_KEYS.RELOAD_TRIGGERED}
        ></isceco-list>
        <div class="button-wrapper">
          <isceco-button
            text="${this.i18n.translate('statistikdaten.freigeben.button')}"
            ?disabled="${this._isFreigebenDisabled}"
            variant="primary"
            @click="${_ => this._freigeben()}">
          </isceco-button>
        </div>
      </div>
    `
  }

  _handleRowSelectionChange = (value, {id}) => {
    if (value) {
      this.selectedStatistikIds.add(id)
      this._checkHeaderWhenAllChecked()
    } else {
      this.selectedStatistikIds.delete(id)
      this._getHeaderCheckbox().value = false
    }
    this.render()
  }

  _checkHeaderWhenAllChecked() {
    const totalChecked = this.selectedStatistikIds.size
    let totalPossibleChecks = 0
    this._getTableCheckboxes().forEach(c => {
      if (!c.hasAttribute('disabled')) {
        totalPossibleChecks++
      }
    })
    if (totalChecked === totalPossibleChecks) {
      this._getHeaderCheckbox().value = true
    }
  }

  _toggleAllSelection = event => {
    const value = event.detail.value
    this._getTableCheckboxes().forEach(checkbox => {
      if (!checkbox.hasAttribute('disabled') && checkbox.value !== value) {
        checkbox.toggle()
      }
    })
  }

  _getTableCheckboxes() {
    return this._getTableShadowRoot().querySelector('.content').querySelectorAll('isceco-checkbox')
  }

  _getHeaderCheckbox() {
    return this._getTableShadowRoot().querySelector('.header').querySelector('isceco-checkbox')
  }

  _getTableShadowRoot() {
    return document.getElementById('statistikOverviewList').shadowRoot
  }

  _listUpdated = e => {
    this.selectedStatistikIds.clear()
    this.render()
  }

  get _isFreigebenDisabled() {
    return this.selectedStatistikIds.size === 0
  }

  _freigeben() {
    this.statistikOverviewService.create({"ids": [...this.selectedStatistikIds]}, "freigeben").then(() => {
      this.selectedStatistikIds.clear()
      send(FreigebenPruefen.EVENT_KEYS.RELOAD_TRIGGERED)
      this.render()
    })
  }

  _rowAction = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.statistikDaten.hash, id: `${row.gesuchId}`})
    }
  }
}
customElements.define('vzavg-statistikdaten-freigeben-pruefen', FreigebenPruefen)
