import BaseService from '../BaseService'

export default class UebersichtService extends BaseService {
  constructor() {
    super('gesuch')
  }

  /**
   * gets the data for the uebersicht
   * @param {string} gesuchId
   * @returns {Promise<*>}
   */
  async getUebersicht(gesuchId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/uebersicht/`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }

  async updateDate(gesuchId, data) {
    await this.retrieveBackendUrl();
    const url = `${this.backendUrl}/${gesuchId}/uebersicht`
    return this.checkError(
      fetch(url, {
        method: 'PUT',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(data)
      }).then(this._resetChangesAndReturn)
    )
  }
}
