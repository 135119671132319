import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Card/Card.js'
import '@isceco/widget-library2/basic-elements/FileInput/FileInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import WebComponent from '../../../WebComponent.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import GesuchService from '../../../services/GesuchService.js'
import DokumentService from '../../../services/DokumentService.js'
import VzavgFileInputService from '../../../services/VzavgFileInputService.js'
import DocumentDokIdUpload from '../../../components/DocumentUpload/DocumentDokIdUpload.js'
import GesuchVerantwortlichePersonService from '../../../services/GesuchVerantwortlichePersonService.js'
import VerantwortlichePersonHelper from '../VerantwortlichePerson/VerantwortlichePersonHelper.js'
import StammdatenService from '../../../services/StammdatenService.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import VzavgFileInputServiceDecorator from "../../../services/VzavgFileInputServiceDecorator";



export default class DokumenteEdit extends WebComponent {

  static DOKUMENTE_EDIT_FORM_ID = 'dokumente-edit-form'
  static MODALID = '#dokument-override-modal'

  static get EVENT_KEYS() {
    return {
      UPLOAD_COMPLETE: 'dokumente-dokument-upload-complete'
    }
  }

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.gesuchService = new GesuchService()
    this.verantwortlichePersonService = new GesuchVerantwortlichePersonService()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.backendData = {}
    this.currentVersion = 1
  }

  get css() {
    return css`
      .flex-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        min-width: 10em;
        margin-right: 2em;
      }
      .flex-container-item {
        flex: 1 0 45%;
      }
      .file-content {
        margin: 10px 0px 0px 0px;
        min-height: 60px;
        border-bottom: lightgray solid 1px;
      }
      .file-content-mobile {
        margin: 10px 0px 0px 0px;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Dokumente/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.dokumentId = this._getDokumentId()
    if (!this.dokumentId) {
      showAlert('Navigation url error', 'dokumentId in url not found', 'error')
      return
    }

    this._refresh = () => this._getDokumentAndRender()
    this._reload = () => this.reload()
    document.addEventListener(DocumentDokIdUpload.EVENT_KEYS.UPDATED, this._refresh)
    window.addEventListener('resize', this._reload)

    this.verantwortlichePersonService = await this.verantwortlichePersonService.getListServiceForDocumentUpload(this.gesuchId)

    // get Stammdaten and filter per dokId
    const dokumentIdStammdaten = await new StammdatenService().getDokumentId()
    this.dokumentIdsGenerell = dokumentIdStammdaten.filter(d => d.code.includes('GE')).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsVermittlung = dokumentIdStammdaten.filter(d => d.code.includes('AV')).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsVerleih = dokumentIdStammdaten.filter(d => d.code.includes('PV')).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsVerantwortlicheP = dokumentIdStammdaten.filter(d => d.code.includes('VP')).map(d => ({name: d.bezeichnung, value: d.code}))

    this.dokIdsKanton = ['BE01', 'BE03', 'VE01', 'VE03', 'VE05', 'RE01']
    this.dokIdsSeco = ['BE02', 'BE04', 'VE02', 'VE04', 'VE06', 'RE02']
    this.dokumentIdsEntscheidKanton = dokumentIdStammdaten
      .filter(d => this.dokIdsKanton.includes(d.code)).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsEntscheidSeco = dokumentIdStammdaten
      .filter(d => this.dokIdsSeco.includes(d.code)).map(d => ({name: d.bezeichnung, value: d.code}))

    this.dokumentService = new DokumentService(this.gesuchId, 'dokumente/file')
    Promise.all([
      this.verantwortlichePersonService.list(),
      this.dokumentService.validationConfig()
    ]).then(([verantwortlichePersonen, dokumentValidationConfig]) => {
      this.verantwortlichePersonen = []
      this.verantwortlichePersonen.json = verantwortlichePersonen.json.filter(p => p.personStatus === 'aktiv')
      this.verantwortlichePersonen.totalCount = this.verantwortlichePersonen.json.length
      this.vzavgFileInputServiceVerantwPerson = new VzavgFileInputService(null, DokumenteEdit.MODALID)
      this.validationConfig = dokumentValidationConfig
      this.url = this.dokumentService.backendUrl
      this._refresh()
    }).catch(_2 => this.reload())
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(DocumentDokIdUpload.EVENT_KEYS.UPDATED, this._refresh)
    window.removeEventListener('resize', this._reload)
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.items = []
  }

  getTemplate() {
    return html`
      <isceco-button
        id="dokument-back"
        variant="secondary"
        icon="arrow left"
        text="${this.i18n.translate('geschaeftsvorfall.dokument.back.to.list')}"
        @click="${_ => this._backToDokumenteList()}">
      </isceco-button>
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.dokument.create.title')}">
      </isceco-title>
      <div class="flex-container">
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.generell')}">
          <vzavg-document-dokid-upload fileInputId="generell-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="dokumente"
            .fileInputService="${new VzavgFileInputService(null, DokumenteEdit.MODALID, this.currentVersion)}"
            .dokIdStammdaten="${this.dokumentIdsGenerell}"
            .dokumentItems="${this._getDokumentItems('GE', this.dokumentIdsGenerell)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendData}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
            .inlineUpload="${!this._isMobileView()}"
          >
          </vzavg-document-dokid-upload>
        </isceco-card>
        <isceco-card class="flex-container-item"  text="${AuthorizationFilter.hasKantonRole() ? this.i18n.translate('geschaeftsvorfall.dokument.box.bewilligung.verfuegung.kanton') : this.i18n.translate('geschaeftsvorfall.dokument.box.bewilligung.verfuegung.seco')}">
          <vzavg-document-dokid-upload fileInputId="bewilligung-verfuegung-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="entscheid"
            .fileInputService="${new VzavgFileInputService(null, DokumenteEdit.MODALID)}"
            .dokIdStammdaten="${AuthorizationFilter.hasKantonRole() ? this.dokumentIdsEntscheidKanton : this.dokumentIdsEntscheidSeco}"
            .dokumentItems="${this._getDokumentItemsForBewVerf(AuthorizationFilter.hasKantonRole() ? this.dokumentIdsEntscheidKanton : this.dokumentIdsEntscheidSeco)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendDataEntscheid}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
            .inlineUpload="${!this._isMobileView()}"
          >
          </vzavg-document-dokid-upload>
        </isceco-card>
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.vermittlung')}">
          <vzavg-document-dokid-upload fileInputId="vermittlung-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="dokumente"
            .fileInputService="${new VzavgFileInputService(null, DokumenteEdit.MODALID)}"
            .dokIdStammdaten="${this.dokumentIdsVermittlung}"
            .dokumentItems="${this._getDokumentItems('AV', this.dokumentIdsVermittlung)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendData}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
            .inlineUpload="${!this._isMobileView()}"
          >
          </vzavg-document-dokid-upload>
        </isceco-card>
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.verleih')}">
           <vzavg-document-dokid-upload fileInputId="verleih-dokument-upload"
            gesuchId="${this.gesuchId}"
            dokumentId="${this.dokumentId}"
            kategorie="dokumente"
            .fileInputService="${new VzavgFileInputService(null, DokumenteEdit.MODALID)}"
            .dokIdStammdaten="${this.dokumentIdsVerleih}"
            .dokumentItems="${this._getDokumentItems('PV', this.dokumentIdsVerleih)}"
            .i18n="${this.i18n}"
            .backendData="${this.backendData}"
            .readonly="${false}"
            .currentVersion="${this.currentVersion}"
            .inlineUpload="${!this._isMobileView()}"
           >
          </vzavg-document-dokid-upload>
        </isceco-card>
        <isceco-card class="flex-container-item" text="${this.i18n.translate('geschaeftsvorfall.dokument.box.verantwpersonen')}">
          ${this._isMobileView() ? this._getVerantwPersonBoxTemplateMobile() : this._getVerantwPersonBoxTemplate()}
        </isceco-card>
      </div>


      <isceco-dialog id="dokument-override-modal"
               hidden
               header="${this.i18n.translate('geschaeftsvorfall.dokument.upload.popup.title')}"
               description="${this.i18n.translate('geschaeftsvorfall.dokument.upload.popup.text')}"
               @submit="${e => e.target.setAttribute('hidden', '')}">

        <div style="display: flex; column-gap: 10px; margin-top: 20px;">
          <isceco-button id="btnAbbrechen" size="small" variant="primary"
                         text="${this.i18n.translate('geschaeftsvorfall.dokument.upload.popup.abbrechen')}"
                         @click="${e => this._handleDokumentUploadModalOption(e)}">
          </isceco-button>
          <isceco-button id="btnLoeschen" size="small" variant="primary"
                         text="${this.i18n.translate('geschaeftsvorfall.dokument.upload.popup.loeschen')}"
                         @click="${e => this._handleDokumentUploadModalOption(e)}">
          </isceco-button>
          <isceco-button id="btnNotiz" size="small" variant="primary" variant="primary"
                         text="${this.i18n.translate('geschaeftsvorfall.dokument.upload.popup.alsnotiz')}"
                         @click="${e => this._handleDokumentUploadModalOption(e)}">
          </isceco-button>
        </div>
      </isceco-dialog>
    `
  }

  _handleDokumentUploadModalOption(e) {
    const modalDialog = document.querySelector(DokumenteEdit.MODALID)
    modalDialog.setAttribute('hidden', '')
    if (e.target.id === 'btnAbbrechen') {
      return
    }

    modalDialog.fileUploadCallBackObject.dialogCallBackUpload(e.target.id === 'btnNotiz')
      .then(res => {
        if (res.status && res.status >= 400) {
          showAlert('', this.i18n.translate('geschaeftsvorfall.dokument.upload.error.message'), 'error')
        } else {
          send(DokumenteEdit.EVENT_KEYS.UPLOAD_COMPLETE)
          this._getDokumentAndRender()
        }
      }).catch(() => {
        showAlert('', this.i18n.translate('geschaeftsvorfall.dokument.upload.error.message', 'error'))
      })

  }

  _getVerantwPersonBoxTemplate() {
    const dokumentItems = this._getDokumentItems('VP', this.dokumentIdsVerantwortlicheP)
    const personen = this.verantwortlichePersonen.totalCount > 0 ? this.verantwortlichePersonen.json.map((person, pIndex) => html`
      <b style="grid-column: ${pIndex+2}/${pIndex+2}; grid-row: 1/2; place-self: center;">${person.name} ${person.vorname}</b>
            ${dokumentItems.map((dokItem, dokIndex) => html `
                ${this._getFileParagraphTemplate(dokItem, dokIndex, person, pIndex)}
            `)}
      `) : html``

    return html`
      <div style="display: grid; row-gap: 10px; overflow-x: auto; grid-template-columns: minmax(20%, 1fr); grid-auto-columns: minmax(15%, 1fr)">
        <div style="grid-column: 1/1; grid-row: 1/2"></div>
        ${dokumentItems.map((dokItem, index) => html `
                <div style="grid-column: 1/1; grid-row: ${index + 2}/${index + 2}; border-bottom: lightgray solid 1px;">
                  <i style="color:white" class="check circle icon"></i>
                  ${dokItem.name}
                </div>
              `)}
        ${personen}
      </div>
    `
  }

  _getVerantwPersonBoxTemplateMobile() {
    const dokumentItems = this._getDokumentItems('VP', this.dokumentIdsVerantwortlicheP)
    const personen = this.verantwortlichePersonen.totalCount > 0 ? this.verantwortlichePersonen.json.map(person => html`
          <div class="flex-container-item" style="margin-bottom: 30px;">
            <b>${person.name} ${person.vorname}</b>
            ${dokumentItems.map(dokItem => html `
                ${this._getFileParagraphTemplateMobile(dokItem, person)}
            `)}
          </div>
      `) : html``

    return html`
      <div class="flex-container">
        <isceco-dropdown class="flex-container-item" id="verantw-person-combo" placeholder="---"
                         ?disabled="${false}"
                         @change="${e => this._activateFileInputAndSetUrl(e, 'verantwPerson-dokument-upload', this.vzavgFileInputServiceVerantwPerson)}"
                         label="Verantw. Person"
                         .items="${VerantwortlichePersonHelper.getComboboxItems(this.verantwortlichePersonen)}">
        </isceco-dropdown>
        <isceco-dropdown class="flex-container-item" id="verantw-person-dokid" placeholder="---" label="Dokument Typ"
                         ?disabled="${false}"
                         @change="${e => this._activateFileInputAndSetUrl(e, 'verantwPerson-dokument-upload', this.vzavgFileInputServiceVerantwPerson)}"
                         .items="${this.dokumentIdsVerantwortlicheP}">
        </isceco-dropdown>
         <isceco-file-input class="flex-container-item" id="verantwPerson-dokument-upload" label="Dokument" disabled
             .fileInputService="${this.vzavgFileInputServiceVerantwPerson}"
             label=""
             accept="${this.validationConfig.allowedExtensions}"
             maxsize="${this.validationConfig.maxSize}"o
             clear-event="${DokumenteEdit.EVENT_KEYS.UPLOAD_COMPLETE}"
             @change="${e => this._onDokumentUploaded(e)}">
         </isceco-file-input>
      </div>
      <br>
      <div style="display: flex; flex-wrap: wrap; overflow-y: hidden;">
        ${personen}
      </div>
    `
  }

  _isMobileView() {
    return window.innerWidth < 991.98
  }

  _setUrl(dokId, personId, service) {
    const alreadyContainedItem = this.backendData.items.filter(i => i.dokId === dokId && i.personId === personId)
    service.setDokId(alreadyContainedItem.length > 0 ? dokId : null)

    service.setUrl(`${this.url}/${dokId}/${personId}`)
  }

  _getFileParagraphTemplate(dokItem, dokIndex, person, pIndex) {
    const dokIdItem = this.backendData.items && this.backendData.items.find(item => item.dokId === dokItem.value && item.personId === person.id)
    const service = new VzavgFileInputServiceDecorator(srv => this._setUrl(dokItem.value, person.id, srv), this.vzavgFileInputServiceVerantwPerson)
    const color = dokItem.mandatory ? 'red' : 'orange'
    return html`
      <div style="grid-column: ${pIndex+2}/${pIndex+2}; grid-row: ${dokIndex+2}/${dokIndex+2}; border-bottom: lightgray solid 1px;">
        <div style="display: grid; row-gap: 1em; grid-template-columns: 20% 80%;">
          <div style="grid-column: 1/2; grid-row: 1/2; align-self: center; justify-self: end;">
            ${dokIdItem ? html`
            <i style="color:green" class="check circle icon"></i>
            ` : html`
              <i style="color:${color}" class="question circle icon"></i>
            `}
          </div>
          <isceco-file-input style="grid-column: 2/2; grid-row: 1/2;" id="vp-${person.id}-${dokItem.value}-file-input"
                             .fileInputService="${service}"
                             accept="${this.validationConfig.allowedExtensions}"
                             maxsize="${this.validationConfig.maxSize}"o
                             clear-event="${DokumenteEdit.EVENT_KEYS.UPLOAD_COMPLETE}"
                             @change="${e => this._onDokumentUploaded(e)}">
          </isceco-file-input>
          <div style="grid-column: 1/ span 2; grid-row: 2/2; place-self: center">
          ${dokIdItem ? html`
            ${this._getFileContent(dokIdItem)}
          ` : html``}
          </div>
        </div>
      </div>
    `
  }

  _getFileParagraphTemplateMobile(dokItem, person) {
    const dokIdItem = this.backendData.items && this.backendData.items.find(item => item.dokId === dokItem.value && item.personId === person.id)
    if (dokIdItem) {
      return html`
        <div class="file-content-mobile">
          <i style="color:green" class="check circle icon"></i>
          ${dokItem.name}
          ${this._getFileContent(dokIdItem)}
        </div>
      `
    } else {
      return html`
        <div class="file-content-mobile">
          <i style="color:${dokItem.mandatory ? 'red' : 'orange'}" class="question circle icon"></i>
          ${dokItem.name}
        </div>
    `
    }
  }

  _getFileContent(dokIdItem) {
     return html`
      <div style="display: flex;">
        <i style="margin-top: 0px;" class="file icon"></i>
        <isceco-button variant="tertiary"
                       size="small"
                       text="${this._getShortName(dokIdItem.fileName)}"
        @click="${_ => this.dokumentService.download(dokIdItem.downloadPath, dokIdItem.fileName)}">
      </isceco-button>
      <isceco-button id="${this._getDeleteBtnId('this.backendData.fileName')}"
                     title="${this.i18n.translate('dokumente.delete.button')}"
                     ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco) || dokIdItem.version !== this.currentVersion}"
                     variant="tertiary"
                     size="small"
                     icon="trash"
                   @click="${_ => this._onDeleteClick(dokIdItem.dokId, dokIdItem.version, dokIdItem.fileName, dokIdItem.personId)}">
        </isceco-button>
      </div>
    `
  }

  _getDokumentItemsForBewVerf(dokumentItemsStammdaten) {
    const ruleItemsGenerell = this.backendData.ruleItems ? this.backendData.ruleItems.filter(ri =>
      this.backendData.isKantonProcess ? this.dokIdsKanton.includes(ri.value) : this.dokIdsSeco.includes(ri.value)) : []
    const notMandatoryItems = dokumentItemsStammdaten.filter(dig =>
      ruleItemsGenerell.find(ruleItem => ruleItem.value === dig.value) === undefined
    )
    notMandatoryItems.forEach(item => ruleItemsGenerell.push({
      'value': item.value,
      'name': item.name,
      'mandatory': false
    }))
    return ruleItemsGenerell.sort((i1, i2) => i1.value.localeCompare(i2.value))
  }

  _getDokumentItems(dokIdPattern, dokumentItemsStammdaten) {
    const ruleItemsGenerell = this.backendData.ruleItems ? this.backendData.ruleItems.filter(ri => ri.value.includes(dokIdPattern)) : []
    const notMandatoryItems = dokumentItemsStammdaten.filter(dig =>
      ruleItemsGenerell.find(ruleItem => ruleItem.value === dig.value) === undefined
    )
    notMandatoryItems.forEach(item => ruleItemsGenerell.push({'value': item.value, 'name': item.name, 'mandatory': false}))
    return ruleItemsGenerell.sort((i1,i2) => i1.value.localeCompare(i2.value))
  }

  _getShortName(name) {
    const maxLength = 30
    return name.length > maxLength ? name.substring(0, maxLength).concat('...') : name
  }

  _getDeleteBtnId(name) {
    return 'btn-delete-'.concat(this._getShortName(name).split(' ').join('').replaceAll('.', ''))
  }

  _onDeleteClick(dokId, version, fileName, personId) {
    const subResource = personId === undefined ? `${version}/${fileName}` : `${personId}/${version}/${fileName}`
    this.dokumentService.delete(dokId, subResource).then(_ => {
      this._refresh()
    })
  }

  _activateFileInputAndSetUrl(e, idFileInput, service) {
    service.setUrl(this.url)
    if(e.detail.value !== '') {
      const personId = document.getElementById('verantw-person-combo').value
      const dokId = document.getElementById('verantw-person-dokid').value
      if(personId !== undefined && !isEmpty(personId) && dokId !== undefined && !isEmpty(dokId)) {
        document.getElementById(idFileInput).removeAttribute('disabled')

        const alreadyContainedItem = this.backendData.items.filter(i => i.dokId === dokId && i.personId === personId)
        service.setDokId(alreadyContainedItem.length > 0 ? e.detail.value : null)

        service.setUrl(`${this.url}/${dokId}/${personId}`)
      }
    } else {
      document.getElementById(idFileInput).setAttribute('disabled','')
    }
  }

  _onDokumentUploaded(e) {
    if (e.detail.valid) {
      send(DokumenteEdit.EVENT_KEYS.UPLOAD_COMPLETE)
      this._getDokumentAndRender()
    }
  }

  async _getDokumentAndRender() {
    try {
      Promise.all([
        this.gesuchService.read(this.gesuchId, `dokument/dokumente/detail/${this.dokumentId}`),
        this.gesuchService.read(this.gesuchId, `dokument/entscheid/detail/${this.dokumentId}`)
      ]).then(([gesuchDokumenteResponse, gesuchDokumenteEntscheidResponse]) => {
        if (isNoDataFound(gesuchDokumenteResponse)) {
          this._initBackendData()
          this.render()
          return
        }

        // object exists already
        this.backendData = gesuchDokumenteResponse
        this.currentVersion = gesuchDokumenteResponse.version
        this.backendDataEntscheid = gesuchDokumenteEntscheidResponse

        send(GeschaeftsvorfallNavigation.EVENT_KEYS.STATUS_CHANGED, {
          target: 'Geschaeftsvorfall_Dokumente',
          status: this.backendData.status
        })
        this.render()
      })
    } catch (_) {
      showAlert('', this.i18n.translate('geschaeftsvorfall.dokument.edit.error'))
      this.render()
    }
  }

  _getDokumentId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _backToDokumenteList() {
    navigate({to: geschaeftsVorfallNavigationLinks.dokumente.hash, id: this.gesuchId})
  }

}
customElements.define('vzavg-dokumente-edit', DokumenteEdit)
