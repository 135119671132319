import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import WebComponent from '../../WebComponent.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter'
import './Navigation/AdministrationNavigation.js'
import HilfeInfoText from './HilfeInfoText/HilfeInfoText.js'
import Dokumentation from './Dokumentation/Dokumentation.js'
import Stammdatenpflege from './Stammdatenpflege/Stammdatenpflege.js'
import Checklisten from './Checklisten/Checklisten.js'
import ChecklistenEdit from './Checklisten/ChecklistenEdit.js'
import Rechte from './Rechte/Rechte.js'

export default class Administration extends WebComponent {

  get translationFile() {
    return './views/Administration/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()

    // set default view
    if (getNavigationHash() === 'Administration') {
      if (AuthorizationFilter.hasSuperRole()) {
        navigate({to: 'Administration_Hilfe'});
      } else {
        navigate({to: 'Administration_Stammdatenpflege'});
      }
    }
    this.render()
  }

  getTemplate() {
    return html`
      <div style="display: flex;">
        <div style="flex-grow: 0; margin-top: 10px;">
          <vzavg-frontend-administration-navigation></vzavg-frontend-administration-navigation>
        </div>
        <div id="administrationContent" style="flex-grow: 1; margin-left: 20px; margin-top: 20px;">
          ${this.getContent()}
        </div>
      </div>
    `
  }

  getContent() {  //NOSONAR
    // check the rights and redirect to default view in verwaltung if not ok
    if (!AuthorizationFilter.viewAllowed(getNavigationHash())) {
      navigate({to: ''})
      return html``
    }
    switch (getNavigationHash()) {
      case 'Administration_Hilfe':
        return new HilfeInfoText();
      case 'Administration_Stammdatenpflege':
      case 'Administration_Stammdatenpflege_Kast':
      case 'Administration_Stammdatenpflege_KastEdit':
      case 'Administration_Stammdatenpflege_Benutzer':
      case 'Administration_Stammdatenpflege_BenutzerEdit':
        return new Stammdatenpflege();
      case 'Administration_Checklisten':
        return new Checklisten();
      case 'Administration_Dokumentation':
        return new Dokumentation();
      case 'Administration_ChecklistenEdit':
        return new ChecklistenEdit();
      case 'Administration_Rechte':
        return new Rechte();
      default:
        break;
    }
    // default
    return html`Default`
  }

  get css() {
    return `
            div {
                text-align: left;
            }
        `
  }
}
customElements.define('vzavg-frontend-administration', Administration)
