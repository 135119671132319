import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/DropdownMultiselect/DropdownMultiselect.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import BewilligungService from '../../../services/BewilligungService.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import StammdatenService from '../../../services/StammdatenService.js'
import VzavgFormation from "../../../common/VzavgFormation";

const BITTE_AUSWAEHLEN_KEY = 'bitte.auswaehlen'
export default class BewilligungEdit extends WebComponent {

  constructor() {
    super()

    this.service = new BewilligungService()
    this.stammdatenService = new StammdatenService()
  }

  get css() {
    return css`
      .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
      }

      @media (max-width: 991.98px) {
        .grid-wrapper {
          grid-template-columns: 1fr;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Bewilligung/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback();
    await this._getDataAndRender()
  }

  getTemplate() {
    return html`
      ${this._renderBackButton()}
      ${this._renderTitle()}
      <isceco-form id="bewilligung-form">
        <div slot="form-elements">
          <div class="grid-wrapper">
            <div class="column">
              ${this._renderBereich()}
              ${this._renderTyp()}
              ${this._renderAction()}
              ${this._renderActionKorrigierenDatums()}
              ${this._renderGrund()}
            </div>
            <div class="column">
              ${this._renderAufhebungsdatum()}
              ${this._renderReaktivierung()}
              ${this._renderWeiterarbeit()}
              ${this._renderInRechtskraftErwachsen()}
            </div>
          </div>
        </div>
      </isceco-form>
       <isceco-dialog
        id="reaktivieren-info-dialog"
        hidden
        header="${this.i18n.translate('bewilligung.reaktivieren.info.dialog.header')}"
        description="${this.i18n.translate('bewilligung.reaktivieren.info.dialog.description')}"
        cancel-button="${this.i18n.translate('bewilligung.reaktivieren.dialog.cancel')}"
        @submit="${e => this._closeConfirmDialog(e)}">
      </isceco-dialog>
       <isceco-dialog
        id="entziehen-option-dialog"
        hidden
        header="${this.i18n.translate('bewilligung.entziehen.dialog.confirm.header')}"
        description="${this.i18n.translate('bewilligung.entziehen.dialog.confirm.description')}"
        confirm-button="${this.i18n.translate('bewilligung.entziehen.dialog.confirm')}"
        cancel-button="${this.i18n.translate('bewilligung.entziehen.dialog.cancel')}"
        @submit="${e => this._closeConfirmDialog(e)}">
      </isceco-dialog>
      <isceco-dialog
        id="entziehen-option-inRechtskraftErwachsen-dialog"
        hidden
        header="${this.i18n.translate('bewilligung.entziehen.inRechtskraftErwachsen.dialog.confirm.header')}"
        description="${this.i18n.translate('bewilligung.entziehen.inRechtskraftErwachsen.dialog.confirm.description')}"
        confirm-button="${this.i18n.translate('bewilligung.entziehen.dialog.confirm')}"
        cancel-button="${this.i18n.translate('bewilligung.entziehen.dialog.cancel')}"
        @submit="${e => this._closeConfirmDialog(e)}">
      </isceco-dialog>
    `
  }

  _renderBackButton() {
    return html`
      <isceco-button
        id="bewilligung-back"
        variant="secondary"
        icon="arrow left"
        text="${this.i18n.translate('back.to.list')}"
        @click="${_ => this._backToList()}"
      ></isceco-button>
    `
  }

  _renderTitle() {
    return html`
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.bewilligung.edit.title')}">
        <nav class="title-navigation">
          ${this.hasWriteAccess ?
            html`
              <isceco-button
                id="speichern"
                variant="primary"
                icon="save"
                @click="${_ => this.save()}"
                ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              ></isceco-button>
            ` : ``}
        </nav>
      </isceco-title>
    `
  }

  _renderBereich() {
    return html`
      <isceco-dropdown
        id="bereich"
        name="bereich"
        required
        value="${this.backendData.bereich}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.bereich')} *"
        .items="${[
          {value: 'kanton', name: this.i18n.translate('geschaeftsvorfall.bewilligung.bereich.kanton')},
          {value: 'seco', name: this.i18n.translate('geschaeftsvorfall.bewilligung.bereich.seco')}
        ]}"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        disabled
      ></isceco-dropdown>
    `
  }

  _renderTyp() {
    const typArray = [this.backendData.typ]
    return html`
      <isceco-dropdown-multiselect
        id="typ"
        name="typ"
        .value="${typArray}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.typ')} *"
        .items="${[
          {value: 'AV', name: this.i18n.translate('geschaeftsvorfall.bewilligung.typ.vermittlung')},
          {value: 'PV', name: this.i18n.translate('geschaeftsvorfall.bewilligung.typ.verleih')}
        ]}"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        disabled
      ></isceco-dropdown-multiselect>
    `
  }

  _renderAction() {
    return html`
      <isceco-dropdown
        id="aktion"
        name="aktion"
        required
        value="${this.backendData.aktionCode}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.action')} *"
        .items="${this.aktionen}"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        @change="${e => {
          this._setGrund(e.detail.value)
          this._updateValue(e, true)
        }}"
        .disabled="${!this.hasWriteAccess}"
      ></isceco-dropdown>
    `
  }

  _setGrund(aktion) {
    this.backendData.grundCode = ''
    if(aktion === 'korrigieren') {
      this.backendData.grundCode = 'eintragsfehler'
      this.backendData.aufhebungsdatum = null
      document.getElementById('reaktivieren-info-dialog').hidden = false
    }
    if(aktion === 'reaktivieren') {
      this.backendData.grundCode = 'firmaReaktiviert'
      this.backendData.aufhebungsdatum = null
      document.getElementById('reaktivieren-info-dialog').hidden = false
    }
  }

  _renderActionKorrigierenDatums() {
    return this._isActionKorrigieren() ? html`
      <isceco-date-input
        id="erstaustellungsdatum"
        name="erstaustellungsdatum"
        value="${this.backendData.erstausstellungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.erstausstellungsdatum')} *"
        @change="${e => this._updateValue(e)}"
        .disabled="${!this.hasWriteAccess}"
        required
      ></isceco-date-input>
      ${isEmpty(this.backendData.aenderungsdatum) ? html`` : html`
        <isceco-date-input
        id="aenderungsdatum"
        name="aenderungsdatum"
        value="${this.backendData.aenderungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.aenderungsdatum')} *"
        @change="${e => this._updateValue(e)}"
        .disabled="${!this.hasWriteAccess}"
        required
        ></isceco-date-input>
      `}
    ` : html``
  }

  _renderGrund() {
    return html`
      <isceco-dropdown
        id="grund"
        name="grund"
        required
        value="${this.backendData.grundCode}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.grund')} *"
        placeholder="${this.i18n.translate(BITTE_AUSWAEHLEN_KEY)}"
        @change="${e => this._updateValue(e)}"
        .disabled="${!this.hasWriteAccess || !this._isActionSelected()}"
        .items="${this.gruende}"
      ></isceco-dropdown>
    `
  }

  _renderAufhebungsdatum() {
    return html`
      <isceco-date-input
        id="aufhebungsdatum"
        name="aufhebungsdatum"
        value="${this.backendData.aufhebungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.datum')} ${this._isActionAufheben() || this._isActionEntziehen() ? '*':''}"
        @change="${e => this._updateValue(e, true)}"
        min="${this.backendData.erstausstellungsdatum}"
        .disabled="${!this.hasWriteAccess || !this._isActionSelected() || this._isActionReaktivieren() || this._isActionKorrigieren()}"
        .required="${this._isActionAufheben() || this._isActionEntziehen()}"
      ></isceco-date-input>
    `
  }

  _renderReaktivierung() {
    return html`
      <isceco-date-input
        id="reaktivierung"
        name="reaktivierung"
        value="${this.backendData.reaktivierung}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.reaktivierung')} ${this._isActionReaktivieren() ? '*':''}"
        @change="${e => this._updateValue(e, true)}"
        min="${VzavgFormation.formatDateToYYYYMMDD(new Date())}"
        .disabled="${!this.hasWriteAccess || !this._isActionReaktivieren()}"
        .required="${this._isActionReaktivieren()}"
      ></isceco-date-input>
    `
  }

  _renderWeiterarbeit() {
    return html`
      <isceco-date-input
        id="weiterarbeit"
        name="weiterarbeit"
        value="${this.backendData.weiterarbeit}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.weiterarbeit')}"
        @change="${e => this._updateValue(e, true)}"
        .disabled="${!this.hasWriteAccess || !this._isActionEntziehen()}"
      ></isceco-date-input>
    `
  }

  _renderInRechtskraftErwachsen() {
    return html`
      <isceco-date-input
        id="inRechtskraftErwachsen"
        name="inRechtskraftErwachsen"
        value="${this.backendData.inRechtskraftErwachsen}"
        label="${this.i18n.translate('geschaeftsvorfall.bewilligung.inRechtskraftErwachsen')}"
        @change="${e => this._updateValue(e, true)}"
        .disabled="${!this.hasWriteAccess || !this._isActionEntziehen()}"
      ></isceco-date-input>
    `
  }

  _isActionSelected() {
    return !isEmpty(this.backendData.aktionCode) && this.backendData.aktionCode !== undefined
  }

  _isActionAufheben() {
    return this.backendData.aktionCode === 'aufheben'
  }

  _isActionEntziehen() {
    return this.backendData.aktionCode === 'entziehen'
  }

  _isActionKorrigieren() {
    return this.backendData.aktionCode === 'korrigieren'
  }

  _isActionReaktivieren() {
    return this.backendData.aktionCode === 'reaktivieren'
  }

  async _getDataAndRender() {
    this.gesuchId = getNavigationId()
    this.bewilligungId = this._getBewilligungId()

    if (!this.bewilligungId) {
      this._initBackendData()
      await this._fetchStammdatenAndRender()
      return
    }

    try {
      const bewilligungResponse = await this.service.getBewilligung(this.gesuchId, this.bewilligungId)
      if (isNoDataFound(bewilligungResponse)) {
        this._initBackendData()
        await this._fetchStammdatenAndRender()
        return
      }

      this.backendData = bewilligungResponse
      this.backendData.kantonCheckList = []
      this.backendData.secoCheckList = []
      this.backendData.typ = this.backendData.typ ? this.backendData.typ : []
      if (!this.backendData.aufhebungsdatum && this.backendData.entzugsdatum) {
        this.backendData.aufhebungsdatum = this.backendData.entzugsdatum
      }

      const hasAccessAsKanton = this.backendData.bereich === 'kanton' && AuthorizationFilter.hasKantonRole() && AuthorizationFilter.isUserFromSameKanton()
      const hasAccessAsSeco = this.backendData.bereich === 'seco' && AuthorizationFilter.hasSecoRole()
      this.hasWriteAccess = hasAccessAsKanton || hasAccessAsSeco
    } catch (_) {
      showAlert('', this.i18n.translate('geschaeftsvorfall.bewilligung.edit.error'))
      this._initBackendData()
    }
    await this._fetchStammdatenAndRender()
  }

  _fetchStammdatenAndRender() {
    this.aktionen = []
    this.gruende = []

    return Promise.all([
      this.stammdatenService.getSelectableBewilligungsaktion(),
      this.stammdatenService.getBewilligunsgrund()
    ])
      .then(([aktions, grunds]) => {
        this.aktionen = aktions.map(aktion => (
          {value: aktion.code, name: aktion.bezeichnung}
        ))
        this.gruende = grunds.map(grund => (
          {value: grund.code, name: grund.bezeichnung}
        ))
      })
      .then(_ => this.render())
  }

  async save(confirm = false) {
    const inRechtskraftErwachsen = !isEmpty(this.backendData.inRechtskraftErwachsen)
    if(!confirm && this._isActionEntziehen()) {
      if((isEmpty(this.backendData.weiterarbeit) && !inRechtskraftErwachsen) || inRechtskraftErwachsen) {
        this._showConfirmDialog(inRechtskraftErwachsen)
        return
      }
    }

    const form = document.getElementById('bewilligung-form')
    if (!form.validate()) {
      return Promise.resolve(false)
    }
    const formDatas = this._prepareFormDataForSaving(form)
    return this._updateAndRefresh(formDatas)
  }

  _showConfirmDialog(inRechtskraftErwachsen) {
    const dialog = document.getElementById(inRechtskraftErwachsen ? 'entziehen-option-inRechtskraftErwachsen-dialog' : 'entziehen-option-dialog')
    dialog.hidden = false
  }

  _closeConfirmDialog(event) {
    event.target.hidden = true
    if (event.detail.confirmed) {
      this.save(true)
    }
  }

  _prepareFormDataForSaving(form) {
    const formDatas = form.getValues()
    formDatas.id = this.backendData.id
    formDatas.bewilligungId = this.backendData.bewilligungId
    formDatas.gesuchId = this.gesuchId
    formDatas.aufhebungsdatum = this.backendData.aufhebungsdatum
    return formDatas
  }

  _updateAndRefresh(formDatas) {
    return this.service.updateBewilligung(formDatas, this.gesuchId, this.bewilligungId)
      .then(_ => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.bewilligung.update.success.title'),
          this.i18n.translate('geschaeftsvorfall.bewilligung.update.success.message'),
          'success'
        )
        this._refresh()
      })
  }

  _updateValue(event, reload) {
    const key = event.target.id
    const value = event.detail.value
    if(key === 'aktion') {
      this.backendData.aktionCode = value
    }
    if(key === 'grund') {
      this.backendData.grundCode = value
    }
    if(key === 'aufhebungsdatum' && Date.parse(this.backendData.weiterarbeit) < Date.parse(value)) {
      this.backendData.weiterarbeit = null
    }
    if (this.backendData[key] !== value) {
      window.hasChanges = true;
      this.backendData[key] = value
    }
    if (reload) {
      this.reload()
    }
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.typ = []
  }

  _getBewilligungId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _backToList() {
    navigate({to: geschaeftsVorfallNavigationLinks.bewilligung.hash, id: this.gesuchId})
  }

  get grund() {
    return [
      {
        value: 'betriebEingestellt',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.betriebEingestellt')
      },
      {value: 'konkurs', name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.konkurs')},
      {
        value: 'bewillNichtErfuellt',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewillNichtErfuellt')
      },
      {
        value: 'sitzverlegung',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.sitzverlegung')
      },
      {
        value: 'bewilRetourniert',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilRetourniert')
      },
      {
        value: 'neuFirmaGegruendet',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.neuFirmaGegruendet')
      },
      {
        value: 'keinVerleihMehr',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.keinVerleihMehr')
      },
      {
        value: 'eintragsfehler',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.eintragsfehler')
      },
      {
        value: 'gesuchUmAufhebung',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesuchUmAufhebung')
      },
      {
        value: 'bewilEntzogen',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilEntzogen')
      },
      {
        value: 'firmaReaktiviert',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.firmaReaktiviert')
      },
      {value: 'weggezogen', name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.weggezogen')},
      {
        value: 'aufenthaltUnbekannt',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.aufenthaltUnbekannt')
      },
      {
        value: 'irrefuerhendeAngabe',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.irrefuerhendeAngabe')
      },
      {
        value: 'gesetzesverstoesse',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesetzesverstoesse')
      },
      {
        value: 'erfassungsfehler',
        name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.erfassungsfehler')
      }
    ]
  }
}
customElements.define('vzavg-bewilligung-edit', BewilligungEdit)
