import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import StammdatenService from '../../../../services/StammdatenService.js'
import {noYesInternationalFlOptions} from '../../../Geschaeftsvorfall/Gesuch/Gesuch.js'
import {betriebStatuses} from '../../../Geschaeftsvorfall/Betrieb/Betrieb.js'
import AdressverzeichnisService from '../../../../services/AdressverzeichnisService.js'

export default class BetriebFilter extends WebComponent {

  constructor() {
    super()

    this.stammdatenService = new StammdatenService();
    this.adressverzeichnisService = new AdressverzeichnisService();
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  set values(val) {
    this._values = val
  }

  get css() {
    return css`
    `
  }

  get booleanItems() {
    return [
      {name: this.i18n.translate('yes'), value: true},
      {name: this.i18n.translate('no'), value: false}
    ]
  }

  get bewilligungspflichtOptions() {
    return [
      {name: this.i18n.translate('yes'), value: 'yes'},
      {name: this.i18n.translate('no'), value: 'no'},
      {name: this.i18n.translate('suchen.betrieb.bewilligungspflicht.inAbklaeruing'), value: 'inAbklaerung'}
    ]
  }

  connectedCallback() {
    super.connectedCallback()

    Promise.all([
      this.stammdatenService.getOrganisation(),
      this.stammdatenService.getBetriebsarten()

    ])
      .then(([kantone, betriebsArten]) => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
        this.betriebsArten = betriebsArten.map(betriebsArt => ({
          value: betriebsArt.code,
          name: betriebsArt.bezeichnung
        }))
      })
      .then(_ => this.render())

    this.countryList = this.adressverzeichnisService.getCountryList()

  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.kanton')}" id="kanton" name="kanton"
          .items="${this.kantone}"
          .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.status')}" id="betriebStatus" name="betriebStatus"
          .items="${betriebStatuses(this.i18n)}"
          .value="${this.values?.betriebStatus}"
        ></isceco-dropdown>
        <isceco-dropdown
          id="arbeitsVermittlung"
          name="arbeitsVermittlung"
          label="${this.i18n.translate('suchen.betrieb.arbeitsVermittlung')}"
          .value="${this.values?.arbeitsVermittlung}"
          .items="${noYesInternationalFlOptions(this.i18n)}"
        ></isceco-dropdown>
        <isceco-dropdown
          id="personalVerleih"
          name="personalVerleih"
          .value="${this.values?.personalVerleih}"
          label="${this.i18n.translate('suchen.betrieb.personalVerleih')}"
          .items="${noYesInternationalFlOptions(this.i18n)}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.betriebsart')}"
          id="betriebsart"
          name="betriebsart"
          .value="${this.values?.betriebsart}"
          .items="${this.betriebsArten}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.zaverbot')}"
          id="hasZaVerbot"
          name="hasZaVerbot"
          .value="${this.values?.hasZaVerbot}"
          .items="${this.booleanItems}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.fl.bewilligung')}"
          id="hasFlBewilligung" name="hasFlBewilligung"
          .value="${this.values?.hasFlBewilligung}"
          .items="${this.booleanItems}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.bewilligungspflicht')}"
          id="hasBewilligungspflicht" name="hasBewilligungspflicht"
          .value="${this.values?.hasBewilligungspflicht}"
          .items="${this.bewilligungspflichtOptions}"
        ></isceco-dropdown>
        <vzavg-searchable-text-input
          label="${this.i18n.translate('suchen.betrieb.land')}"
          id="land" name="land"
          .value="${this.values?.land}"
          .items="${this.countryList}"
          maxLength="255"
        ></vzavg-searchable-text-input>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.portal.user')}"
          id="isPortalUser" name="isPortalUser"
          .value="${this.values?.isPortalUser}"
          .items="${this.booleanItems}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.hasGemeinnuetzigkeit')}"
          id="hasGemeinnuetzigkeit" name="hasGemeinnuetzigkeit"
          .value="${this.values?.hasGemeinnuetzigkeit}"
          .items="${this.booleanItems}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.betrieb.has.not.verantwortliche.person')}"
          id="hasNotVerantwP" name="hasNotVerantwP"
          .value="${this.values?.hasNotVerantwP}"
          .items="${this.booleanItems}"
        ></isceco-dropdown>
      </div>
    `
  }


}
customElements.define('vzavg-frontend-suche-betrieb-filter', BetriebFilter)
