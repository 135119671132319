import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/RadioGroup/RadioGroup.js'
import '../../../components/CountryInput/CountryInput.js'
import '../../../components/DateInputWithWarning/DateInputWithWarning.js'
import '../../../components/Person/PersonDiffDialog.js'
import '../../../components/Person/PersonBetriebInfo.js'
import AdressverzeichnisService from '../../../services/AdressverzeichnisService.js'
import Options from '../../../common/Options.js'
import PersonService from '../../../services/PersonService.js'
import StammdatenService from '../../../services/StammdatenService.js'
import VzavgFormation from '../../../common/VzavgFormation.js'
import GesuchVerantwortlichePersonService from '../../../services/GesuchVerantwortlichePersonService.js'
import VzavgPattern from '../../../common/VzavgPattern.js'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import DataHelper from '../Common/DataHelper.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import '../../../components/Title/Title.js'
import Dates from '../../../common/Dates.js'

export default class VerantwortlichePersonEdit extends WebComponent {
  static VERANTWORTLICHE_PERSON_FORM_ID = 'verantwortliche-person-form';
  static SWITZERLAND = 'Schweiz';

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.verantwortlichePersonService = new GesuchVerantwortlichePersonService()
    this.stammdatenService = new StammdatenService()
    /**
     * VerantwortlichePerson Type Definition
     * @typedef {Object} VerantwortlichePerson
     * @property {Object} kantonCheckList
     * @property {Object} secoCheckList
     * @property {number} [id]
     * @property {number} [personId]
     * @property {String} sozVersNr
     * @property {String} hasNoSozVersNr
     * @property {String} name
     * @property {String} vorname
     * @property {String} email
     * @property {String} gebDatum
     * @property {String} heimatort
     * @property {String} nationalitaet
     * @property {Boolean} hasAufenthaltsbewilligung
     * @property {String} sperrdatumVon
     * @property {String} sperrdatumBis
     * @property {String} taetigVon
     * @property {String} taetigBis
     * @property {boolean} isVollzeit
     * @property {String} teilzeitGrund
     * @property {GeschaeftsvorfallNavigation.STATUS_TYPE} statusTyp
     * @property {Object} kantonCheckListChecks
     * @property {Object} secoCheckListChecks
     * @property {String} gesuchId
     * @property {number} prio
     * @property {String} status
     *
     * @type {VerantwortlichePerson}
     */
    this.backendData = {}
    this.otherBetriebe = null;

    this.personService = new PersonService();
    this.addressverzeichnisService = new AdressverzeichnisService()
    this.heimatorte = []
  }

  get css() {
    return css`

      [hidden] {
        display: none;
      }

      nav {
        padding-bottom: 0.5rem;
      }

      .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .buttons-column {
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: start;
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/VerantwortlichePerson/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()

    this.personListService = await this.personService.getListService();

    this.heimatorte = this.addressverzeichnisService.communities

    this.teilzeitReasons = await this._loadTeilzeitGrundStammdaten()
    await this._getPersonAndRender()
  }

  getTemplate() {
    return html`
      <div class="header-wrapper">
        <isceco-button
          id="verantwortliche-person-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.back.to.list')}"
          @click="${_ => this._backToVerantwortlichePersonList()}"
        ></isceco-button>
        ${this._renderOtherBetriebe()}
      </div>
      ${this._renderTitle()}
      <isceco-form id="${VerantwortlichePersonEdit.VERANTWORTLICHE_PERSON_FORM_ID}">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>

      ${this._renderDialogs()}
    `
  }

  _renderTitle() {
    return html`
      <vzavg-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.create.title')}">
        <div slot="center" class="title-content">
          <isceco-button
            id="previousVP"
            icon="chevron left"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.adresse.hash, id: this.gesuchId})}"
          ></isceco-button>
          ${this.isActive ? html`
            <vzavg-checklist
              id="kantonCheckListChecks"
              name="kantonCheckListChecks"
              type="kanton"
              .items="${this.backendData.kantonCheckList}"
              .isChecklistOverridden="${this.backendData.isKantonChecklistOverridden}"
              .confirmCallback="${_ => this._handleKantonCheckList()}"
            ></vzavg-checklist>
            <vzavg-checklist
              id="secoCheckListChecks"
              name="secoCheckListChecks"
              type="seco"
              .items="${this.backendData.secoCheckList}"
              .isChecklistOverridden="${this.backendData.isSecoChecklistOverridden}"
              .confirmCallback="${_ => this._handleSecoCheckList()}"
            ></vzavg-checklist>
          ` : html``}
          <isceco-button
            id="nextVP"
            icon="chevron right"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.kaution.hash, id: this.gesuchId})}"
          ></isceco-button>
        </div>
        <div slot="right" class="title-content">
          ${this.verantwPersonId ? html`
          <isceco-button
            id="buttonDokumenteAnzeigen"
            icon="file pdf outline"
            variant="secondary"
            @click="${_ => this._openDokumenteTab(this.verantwPersonId)}"
          ></isceco-button>
          ` : html``}
          <isceco-button
            id="verantw-person-add-btn"
            title="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.button.create')}"
            icon="plus"
            variant="primary"
            @click="${_ => navigate({
              to: geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash,
              id: this.gesuchId
            })}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="veratwortliche-person-speichern"
            variant="primary"
            icon="save"
            @click="${_ => this.save()}"
            ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
          ></isceco-button>
          <vzavg-checklist-ok
            id="checklistOk"
            name="checklistOk"
            .disabled="${this.backendData.isChecklistOverridden}"
            .hidden="${window.betrieb$?.getValue()?.bewilligungspflicht === 'no' || !this.isActive}"
            .clickCallback="${_ => this._handleChecklistOverride()}"
          ></vzavg-checklist-ok>
        </div>
      </vzavg-title>
    `
  }

  _renderOtherBetriebe() {
    return this.otherBetriebe?.length ? html`
      <vzavg-person-betrieb-info
        .companies="${this.otherBetriebe}"
        verantwortlichePersonUrl="${geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash}"
        .i18n="${this.i18n}"
      ></vzavg-person-betrieb-info>
    ` : html``
  }

  async _getPersonAndRender() {
    this.gesuchId = getNavigationId()
    this.verantwPersonId = this._getVerantwPersonId()

    if (!this.verantwPersonId) {
      this._initBackendData()
      this.isActive = true
      this.render()
      return
    }

    try {
      const gesuchVerantwortlicherPersonResponse = await this.verantwortlichePersonService.getVerantwortlichePerson(this.gesuchId, this.verantwPersonId)
      if (isNoDataFound(gesuchVerantwortlicherPersonResponse)) {
        this._initBackendData()
        this.isActive = true
        this.render()
        return
      }

      // object exists already
      this.backendData = gesuchVerantwortlicherPersonResponse
      this.isActive = this._calculateIsActive()
      this.backendData.kantonCheckList = []
      this.backendData.secoCheckList = []
      this.backendData.isChecklistOverridden = AuthorizationFilter.hasKantonRole() ? this.backendData.isKantonChecklistOverridden : this.backendData.isSecoChecklistOverridden
      this.otherBetriebe = await this.verantwortlichePersonService.getVerantwortlichePersonBetrieb(this.gesuchId, this.backendData.sozVersNr)
      await this._loadChecklist(this.verantwPersonId)

      this.render()
    } catch (_) {
      showAlert('', this.i18n.translate('geschaeftsvorfall.verantwortliche.person.edit.error'))
      this.render()
    }
  }

  async _loadChecklist(verantwPersonId) {
    const checkListsResponse = await this.verantwortlichePersonService.readVerantwortlichePersonChecklist(this.gesuchId, verantwPersonId)
    if (checkListsResponse && checkListsResponse.checks) {
      for (const check of checkListsResponse.checks) {
        if (check.bereich === 'kanton') {
          this.backendData.kantonCheckList.push(check)
        } else {
          this.backendData.secoCheckList.push(check)
        }
      }
    }
  }

  _renderFormFields() {
    return html`
      <div class="flex">
        <div class="flex-column">
          <isceco-text-input
            id="sozVersNr"
            name="sozVersNr"
            value="${this.backendData.sozVersNr}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.ahvNr')}"
            placeholder="756.xxxx.xxxx.xx"
            pattern="[7][5][6][.][\\d]{4}[.][\\d]{4}[.][\\d]{2}"
            pattern-error-text="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.ahvNr.invalid')}"
            .disabled="${this.backendData.hasNoSozVersNr === 'yes'}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-text-input>
          <isceco-radio-group
            id="hasNoSozVersNr"
            name="hasNoSozVersNr"
            value="${this.backendData.hasNoSozVersNr}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.noAhvNr')} *"
            .items="${Options.yesNo}"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-radio-group>
          <isceco-radio-group
            id="gender"
            name="gender"
            value="${this.backendData.gender}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.gender')} *"
            .items="${Options.gender}"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></isceco-radio-group>
          <isceco-text-input
            id="name"
            name="name"
            maxlength="50"
            required
            value="${this.backendData.name}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.name')} *"
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-text-input>
          <isceco-text-input
            id="vorname"
            name="vorname"
            maxlength="50"
            required
            value="${this.backendData.vorname}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.vorname')} *"
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-text-input>
          <vzavg-date-input-with-warning
            id="gebDatum"
            name="gebDatum"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.gebDatum')} *"
            value="${this.backendData.gebDatum}"
            .warningMinDate="${this._getEightyYearsAgoDate()}"
            .warningMaxDate="${this._getEighteenYearsAgoDate()}"
            .warningMaxDescription="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.other.dateWarningMax')}"
            .warningMinDescription="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.other.dateWarningMin')}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></vzavg-date-input-with-warning>

          <vzavg-country-input
            id="nationalitaet"
            name="nationalitaet"
            placeholder="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.nationalitaet.placeholder')}"
            .label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.nationalitaet')} *"
            value="${this.backendData.nationalitaet}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            ?required="${true}"
            @valueChanged="${e => this._updateValue(e, true)}"
          ></vzavg-country-input>

          ${this.backendData.nationalitaet === VerantwortlichePersonEdit.SWITZERLAND ? html`
            <vzavg-searchable-text-input
              id="heimatort"
              name="heimatort"
              placeholder="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.heimatOrt.placeholder')}"
              required
              value="${this.backendData.heimatort}"
              label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.heimatOrt')} *"
              .items="${this.mappedHeimatorte}"
              @change="${e => this._updateValue(e)}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}">
            </vzavg-searchable-text-input>
          ` : html``}
          ${this.backendData.nationalitaet !== VerantwortlichePersonEdit.SWITZERLAND ? html`
            <isceco-radio-group
              id="hasAufenthaltsbewilligung"
              name="hasAufenthaltsbewilligung"
              value="${this.backendData.hasAufenthaltsbewilligung}"
              label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.aufenthaltsbewilligung')} *"
              .items="${[{
                name: this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.ja'),
                value: true
              }, {
                name: this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.nein'),
                value: false
              }]}"
              @click="${e => e.stopPropagation()}"
              @change="${e => this._updateValue(e)}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
              required
            ></isceco-radio-group>
          ` : html``}

        </div>
        <div class="flex-column">
          <isceco-text-input
            id="email"
            name="email"
            value="${this.backendData.email}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.email')} *"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            pattern="${VzavgPattern.emailPattern}"
            @change="${e => this._updateValue(e)}"
            pattern-error-text="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.email.pattern.error')}"
            required
          ></isceco-text-input>
          <isceco-date-input
            id="taetigVon"
            name="taetigVon"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.taetigVon')} *"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            value="${this.backendData.taetigVon}"
            required
          ></isceco-date-input>
          <isceco-date-input
            id="taetigBis"
            name="taetigBis"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.taetigBis')}"
            type="date"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            min="${this._addDaysToDate(this.backendData.taetigVon, 1)}"
            value="${this.backendData.taetigBis}"
          ></isceco-date-input>
          <isceco-radio-group
            id="isVollzeit"
            name="isVollzeit"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.beschaeftigungsGrad')} *"
            value="${this.backendData.isVollzeit}"
            .items="${[
              {
                value: true,
                name: this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.vollzeit')
              },
              {
                value: false,
                name: this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.teilzeit')
              }]}"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            required
          ></isceco-radio-group>
          <isceco-radio-group
            id="teilzeitGrund"
            name="teilzeitGrund"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.grundWennTeilzeit')} *"
            value="${this.backendData.teilzeitGrund}"
            required
            @change="${e => this._updateValue(e)}"
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            ?hidden=${this.backendData.isVollzeit || this.backendData.isVollzeit === undefined}
            .items="${this.teilzeitReasons}"
          ></isceco-radio-group>
          <isceco-date-input
            id="sperrdatumVon"
            name="sperrdatumVon"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.sperrdatumVon')}"
            value="${this.backendData.sperrdatumVon}"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
          >
          </isceco-date-input>
          <isceco-date-input
            id="sperrdatumBis"
            name="sperrdatumBis"
            min="${this._addDaysToDate(this.backendData.sperrdatumVon, 1)}"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.sperrdatumBis')}"
            value="${this.backendData.sperrdatumBis}"
            @change="${e => this._updateValue(e, true)}"
            ?readonly="${!AuthorizationFilter.hasUserWriteAccess()}"
          ></isceco-date-input>
        </div>
      </div>
    `
  }

  get mappedHeimatorte() {
    return this.heimatorte
      .map(h => ({name: h.name, value: h.name}))
      .sort((a, b) => {
        const ortA = a.name.toLowerCase();
        const ortB = b.name.toLowerCase();

        if (ortA < ortB) {
          return -1
        }
        if (ortB < ortA) {
          return 1
        }

        return 0
      })
  }

  _renderDialogs() {
    return html`
      <vzavg-person-diff-dialog
        id="person-diff-dialog"
        .i18n="${this.i18n}"
        .verantwortlichePersonEditUrl="${geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash}"
      ></vzavg-person-diff-dialog>
    `
  }

  _updateValue(event, reload) {
    const key = event.target.id
    const value = event.detail.value === undefined ? event.detail : event.detail.value
    if (this.backendData[key] !== value) {
      window.hasChanges = true;
      this.backendData[key] = value
    }
    if (key === 'sperrdatumVon') {
      this._updateSperrDatum(value)
    }
    if (key === 'hasNoSozVersNr') {
      if (value === 'yes') {
        this.backendData.nationalitaet = (this.backendData.nationalitaet && this.backendData.nationalitaet !== VerantwortlichePersonEdit.SWITZERLAND) ?
          this.backendData.nationalitaet : undefined
      } else {
        this.backendData.nationalitaet = this.backendData.nationalitaet ?
          this.backendData.nationalitaet : VerantwortlichePersonEdit.SWITZERLAND
      }
    }
    this.isActive = this._calculateIsActive()
    if (reload) {
      this.reload();
    }
  }

  /**
   * adds provided amount of days to date and returns Date String
   * @param date {String}
   * @param days {number}
   * @returns {String}
   * @private
   */
  _addDaysToDate(date, days) {
    const minDate = new Date(date)
    minDate.setDate(minDate.getDate() + days)
    return VzavgFormation.formatDateToYYYYMMDD(minDate)
  }

  _backToVerantwortlichePersonList() {
    navigate({to: geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash, id: this.gesuchId})
  }

  /**
   * Overrides the user information if the user chooses or takes the existing once
   * @param {PersonDiffTypes.DiffResult} diffResult
   * @private
   */
  _handleDiffResultCallback = diffResult => {
    if (diffResult.confirmed) {
      // overwrite existing person with given values
      this.backendData.personId = diffResult.person.id
      if (diffResult.overwrite) {
        const formDatas = this._prePareFormDataForSaving()
        return this._createOrUpdate(formDatas)
      } else {
        // replace values with existing person
        let formDatas = this._prePareFormDataForSaving()
        // VerantwortlichePerson and Person do have an id and we need to map them properly
        formDatas = {...formDatas, ...diffResult.person, id: formDatas.id, personId: diffResult.person.id}
        this.backendData = formDatas
        return this._createOrUpdate(formDatas)
      }
    }
    return Promise.resolve(false)
  }

  /**
   * prepares the form data for saving
   * @returns {VerantwortlichePerson} formDatas
   * @private
   */
  _prePareFormDataForSaving() {
    const form = document.getElementById(VerantwortlichePersonEdit.VERANTWORTLICHE_PERSON_FORM_ID);

    /**
     * @type {VerantwortlichePerson}
     * **/
    const formData = form.getValues();
    formData.sperrdatumBis = this.backendData.sperrdatumBis
    formData.gebDatum = this.backendData.gebDatum
    formData.id = this.backendData.id
    formData.personId = this.backendData.personId
    formData.gesuchId = this.gesuchId
    formData.hasAufenthaltsbewilligung = formData.hasAufenthaltsbewilligung === null || formData.nationalitaet === VerantwortlichePersonEdit.SWITZERLAND ?
      undefined : formData.hasAufenthaltsbewilligung

    formData.kantonCheckListChecks = this.kantonCheckListChecks
    formData.secoCheckListChecks = this.secoCheckListChecks
    formData.isKantonChecklistOverridden = this.backendData.isKantonChecklistOverridden
    formData.isSecoChecklistOverridden = this.backendData.isSecoChecklistOverridden

    return formData
  }

  _getEighteenYearsAgoDate() {
    const today = new Date();
    return new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  }

  _getEightyYearsAgoDate() {
    const today = new Date();
    return new Date(today.getFullYear() - 80, today.getMonth(), today.getDate());
  }

  _getVerantwPersonId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.hasNoSozVersNr = 'no'
    this.backendData.nationalitaet = VerantwortlichePersonEdit.SWITZERLAND
    this.backendData.taetigVon = new Date().toISOString().split('T')[0]
    this.backendData.hasAufenthaltsbewilligung = true
    this.backendData.isVollzeit = true
    this.backendData.kantonCheckList = []
    this.backendData.secoCheckList = []
    this.backendData.isKantonChecklistOverridden = false
    this.backendData.isSecoChecklistOverridden = false
    this.backendData.isChecklistOverridden = false
  }

  /**
   * Adds two years to the SperrdatumBis when sperrDatumVon changed and renders the form new.
   * @param {String} date
   * @private
   */
  _updateSperrDatum(date) {
    if (date.length) {
      const newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() + 2);
      this.backendData.sperrdatumBis = VzavgFormation.formatDateToYYYYMMDD(newDate)
    } else {
      this.backendData.sperrdatumBis = null
    }
  }

  /**
   * Opens the diff dialog and sets the existing and new date and their differences
   * @param {Person} existingPerson
   * @param {Person} newPersonValues
   * @param {Set} changedFields
   * @private
   */
  async _openDiffDialog(existingPerson, newPersonValues, changedFields) {
    const dialog = document.querySelector('#person-diff-dialog')
    dialog.otherBetriebe = await this.verantwortlichePersonService.getVerantwortlichePersonBetrieb(getNavigationId(), existingPerson.sozVersNr)
    dialog.existingPerson = existingPerson
    dialog.newPersonValues = newPersonValues
    dialog.changedFields = changedFields

    return new Promise(resolve => {
      dialog.callback = diffResult => {
        this._handleDiffResultCallback(diffResult).then(saved => {
          resolve(saved)
        })
      }
      document.querySelector('#person-diff-dialog').shadowRoot
        .querySelector('#isceco-person-diff-dialog')
        .removeAttribute('hidden')
    })
  }

  /**
   * takes the form data and returns the person data only
   * @param {VerantwortlichePerson} data
   * @return {Person}
   * @private
   */
  _getExistingPersonData(data, id) {
    const {
      sozVersNr,
      name,
      vorname,
      gebDatum,
      heimatort,
      nationalitaet,
      hasAufenthaltsbewilligung
    } = data;
    return {sozVersNr, name, vorname, gebDatum, heimatort, nationalitaet, hasAufenthaltsbewilligung, id};
  }

  /**
   * handles the workflow when a person exists and changes were made to the person
   * @param {VerantwortlichePerson} formDatas
   * @private
   */
  _handleSaveWithExistingPerson(formDatas) {
    if (formDatas.sozVersNr) {
      return this.personListService.list({'person-sozVersNr': formDatas.sozVersNr})
        .then(result => this._handlePersonSearchResult(result, formDatas))
    } else {
      return this._createOrUpdate(formDatas)
    }
  }

  _handlePersonSearchResult(result, formDatas) {
    const list = result.json;
    if (list.length === 1) {
      const existingPerson = this._getExistingPersonData(list[0], list[0].id)
      const newPersonValues = this._getExistingPersonData(formDatas, formDatas.personId)
      const [hasChangedFields, changedFields] = DataHelper.compareData(existingPerson, newPersonValues)
      if (!hasChangedFields && newPersonValues.id === existingPerson.id) {
        return this._createOrUpdate(formDatas)
      } else {
        return this._openDiffDialog(existingPerson, newPersonValues, changedFields)
      }
    } else {
      return this._createOrUpdate(formDatas)
    }
  }

  _handleKantonCheckList() {
    this.kantonCheckListChecks = document.getElementById('kantonCheckListChecks').getValue()
    this.save()
  }

  _handleSecoCheckList() {
    this.secoCheckListChecks = document.getElementById('secoCheckListChecks').getValue()
    this.save()
  }

  async save() {
    const form = document.getElementById(VerantwortlichePersonEdit.VERANTWORTLICHE_PERSON_FORM_ID);
    if (!form.validate()) {
      return Promise.resolve(false);
    }

    const formDatas = this._prePareFormDataForSaving()

    return this._handleSaveWithExistingPerson(formDatas)
  }

  _createOrUpdate(formDatas) {
    if (formDatas.id) {
      return this._update(formDatas)

    } else {
      return this._create(formDatas)
    }
  }

  _update(formDatas) {
    return this.verantwortlichePersonService.updateVerantwortlichePerson(formDatas, this.gesuchId)
      .then(_ => {
        this._showAlertAndNavigate(formDatas.id)
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
        return true
      }).catch(_ => false)
  }

  _create(formDatas) {
    return this.verantwortlichePersonService.createVerantwortlichePerson(formDatas, this.gesuchId)
      .then(response => {
        const location = response.headers.get('Location')
        this._showAlertAndNavigate(location.slice(location.lastIndexOf('/') + 1))
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
        return true
      }).catch(_ => false)
  }


  _showAlertAndNavigate(verantwPersonId) {
    showAlert(
      this.i18n.translate('geschaeftsvorfall.verantwortliche.person.create.success.title'),
      this.i18n.translate('geschaeftsvorfall.verantwortliche.person.create.success.message'),
      'success'
    )

    if (this._getVerantwPersonId() === verantwPersonId.toString()) {
      this._getPersonAndRender()
    } else {
      // navigate to refresh the tab statuses (handled in GeschaeftsvorfallNavigation.js parent object)
      navigate({
        to: geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash,
        id: `${this.gesuchId}/${verantwPersonId}`
      })
    }
  }

  /**
   * Konvertiert ein Stammdaten Item zu einem Item für Listen im Formular (Dropdowns, radio Groups, ect.)
   * @typedef StammdatenItem
   * @property {any} value;
   * @property {String} bezeichnung
   * @param {StammdatenItem} item
   * @returns {*} item for dropdowns, radio groups
   * @private
   */
  _mapStammdatenItemsToFormItems = item => ({value: item.code, name: item.bezeichnung});


  async _loadTeilzeitGrundStammdaten() {
    const teilzeitReasons = await this.stammdatenService.getTeilzeitGrund()
    return teilzeitReasons.map(this._mapStammdatenItemsToFormItems)
  }

  _handleChecklistOverride() {
    if (AuthorizationFilter.hasKantonRole()) {
      this.backendData.isKantonChecklistOverridden = true
    } else {
      this.backendData.isSecoChecklistOverridden = true
    }
    this.backendData.isChecklistOverridden = true
    this.save()
  }

  _calculateIsActive() {
    const now = Dates.getToday()

    return Dates.isBetween(now, new Date(this.backendData.taetigVon), new Date(this.backendData.taetigBis)) &&
      !Dates.isBetween(now, new Date(this.backendData.sperrdatumVon), new Date(this.backendData.sperrdatumBis))

  }

  _openDokumenteTab(vPersonId) {
    const to = `#${geschaeftsVorfallNavigationLinks.dokumente.hash}`
    const id = this.gesuchId
    navigate({to, id, params: {'personId': vPersonId}});
  }
}
customElements.define('vzavg-verantwortliche-person-edit', VerantwortlichePersonEdit)
