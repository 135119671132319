import {html, css} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import NotizService from '../../../services/NotizService.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import VzavgFormation from '../../../common/VzavgFormation.js'
import StammdatenService from '../../../services/StammdatenService.js'
import '../../../components/DocumentUpload/DocumentUpload.js'

export default class NotizEdit extends WebComponent {
  static NOTIZ_FORM_ID = 'notiz-form';

  get notizId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  get typOptions() {
    return [{value: true, name: this.i18n.translate('geschaeftsvorfall.notiz.form.externe.notiz')},
      {value: false, name: this.i18n.translate('geschaeftsvorfall.notiz.form.interne.notiz')}]
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Notiz/i18n.json'
  }

  get css() {
    return css`

      [hidden] {
        display: none;
      }

      nav {
        padding-bottom: 0.5rem;
      }

      .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .buttons-column {
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: start;
      }

      .title-content {
        display: flex;
        gap: 2em;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }

      .title-navigation {
        display: flex;
        gap: 1em;
      }
    `
  }

  constructor() {
    super()
    this.notizService = new NotizService()
    this.stammdatenService = new StammdatenService()
    this.notizKategorien = []
  }

  async connectedCallback() {
    super.connectedCallback()
    this._getNotizKategorien()
      .then(_ => this._getNotizAndRender())
  }

  _getNotizAndRender() {
    this.gesuchId = getNavigationId()

    if (!this.notizId) {
      this._initBackendData()
      this.render()
      return
    }

    this.notizService.getNotiz(this.gesuchId, this.notizId).then(gesuchNotizResponse => {
      if (isNoDataFound(gesuchNotizResponse)) {
        this._initBackendData()
        this.render()
        return
      }
      // object exists already
      this.backendData = gesuchNotizResponse
      const lastAuthor = this.backendData.lastAuthor
      const lastDate = VzavgFormation.formatDateToHuman(this.backendData.lastModificationTime)
      this.backendData.editableText = `\n----------------------------------------\n${lastAuthor} / ${lastDate}\n${this.backendData.text}`
    }).catch (_  => {
      showAlert('', this.i18n.translate('geschaeftsvorfall.notiz.edit.error'))
    }).then(_ => this.render())
  }

  async _getNotizKategorien() {
    return this.stammdatenService.getNotizKategorien().then(response => {
      this.notizKategorien = response.map(item => {
        return {name: item.bezeichnung, value: item.code}
      })
    });
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.isExtern = true
    this.backendData.lastModificationTime = VzavgFormation.formatDateToYYYYMMDD(new Date())
    this.backendData.lastModifiedBy = keycloak.tokenParsed.email
    this.backendData.editableText = ''

    const navigationParams = getNavigationParams()
    if (navigationParams.has('kategorie')) {
      this.backendData.kategorie = navigationParams.get('kategorie')
    } else {
      this.backendData.kategorie = null
    }

    if (navigationParams.has('title')) {
      this.backendData.titel = decodeURIComponent(navigationParams.get('title'))
    } else {
      this.backendData.titel = null
    }
  }

  _navigateBackToNotizList() {
    navigate({to: geschaeftsVorfallNavigationLinks.notiz.hash, id: this.gesuchId})
  }

  _navigateToSavedNotiz(notizId) {
    navigate({to: geschaeftsVorfallNavigationLinks.notizEdit.hash, id: `${this.gesuchId}/${notizId}`})
  }

  _updateValue(event, reload) {
    const key = event.target.id
    const value = event.detail.value
    if (this.backendData[key] !== value) {
      window.hasChanges = true;
      this.backendData[key] = value
    }
    if (reload) {
      this.reload();
    }
  }

  save() {
    const form = document.getElementById(NotizEdit.NOTIZ_FORM_ID);
    if (!form.validate()) {
      return Promise.resolve(false)
    }
    const formData = form.getValues()
    if (this.backendData.id) {
      formData.id = this.backendData.id
    }
    return this._createOrUpdate(formData)
  }

  _createOrUpdate(formDatas) {
    if (formDatas.id) {
      return this._update(formDatas)

    } else {
      return this._create(formDatas)
    }
  }

  async _update(formDatas) {
    return this.notizService.updateNotiz(formDatas, this.gesuchId)
      .then(_ => {
        this._showSavedAlert()
        return true
      }).catch(_ => false)
  }

  async _create(formDatas) {
    return this.notizService.createNotiz(formDatas, this.gesuchId)
      .then(resp => {
        this._showSavedAlert()
        const newNotizId = this._getNewNotizId(resp.headers.get('Location'))
        newNotizId ? this._navigateToSavedNotiz(newNotizId) : this._navigateBackToNotizList()
        return true
      }).catch(_ => false)
  }

  _getNewNotizId(location) {
    if (!location) {
      return undefined
    }
    const locationParts = location.split('/')
    const newNotizId = Number(locationParts[locationParts.length - 1])
    return Number.isInteger(newNotizId) ? newNotizId : undefined
  }

  _showSavedAlert() {
    showAlert(
      this.i18n.translate('geschaeftsvorfall.notiz.create.success.title'),
      this.i18n.translate('geschaeftsvorfall.notiz.create.success.message'),
      'success'
    )
  }

  getTemplate() {
    return html`
      <div class="header-wrapper">
        <isceco-button
          id="notiz-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('geschaeftsvorfall.notiz.back.to.list')}"
          @click="${_ => this._navigateBackToNotizList()}"
        ></isceco-button>
      </div>
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.notiz.create.title')}">
        <nav class="title-navigation">
          <isceco-button
            id="notiz-speichern"
            variant="primary"
            icon="save"
            @click="${_ => this.save()}"
            ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
          ></isceco-button>
        </nav>
      </isceco-title>
      <isceco-form id="${NotizEdit.NOTIZ_FORM_ID}">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>
    `
  }

  _renderFormFields() {
    return html`
      <div class="flex">
        <div class="flex-column">
          <isceco-text-input
            id="titel"
            name="titel"
            value="${this.backendData.titel}"
            label="${this.i18n.translate('geschaeftsvorfall.notiz.titel')} *"
            @change="${e => this._updateValue(e)}"
            ?readonly="${this.backendData.isReadonly}"
            required
            maxlength="255"
          ></isceco-text-input>
          <isceco-radio-group
            id="isExtern"
            name="isExtern"
            value="${this.backendData.isExtern}"
            label="${this.i18n.translate('geschaeftsvorfall.notiz.typ')} *"
            .items="${this.typOptions}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${this.backendData.isReadonly}"
            required
          ></isceco-radio-group>
          <isceco-dropdown
            id="kategorie"
            name="kategorie"
            value="${this.backendData.kategorie}"
            label="${this.i18n.translate('geschaeftsvorfall.notiz.kategorie')} *"
            .items="${this.notizKategorien}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${this.backendData.isReadonly}"
            required>
          </isceco-dropdown>
          <isceco-textarea
            id="text"
            name="text"
            required
            value="${this.backendData.editableText}"
            label="${this.i18n.translate('geschaeftsvorfall.notiz.form.notiz')} *"
            @change="${e => this._updateValue(e)}"
            ?readonly="${this.backendData.isReadonly}"
            resize="auto"
            maxlength="2000"
          ></isceco-textarea>
        </div>
        <div class="flex-column">
          <isceco-text-input
            id="benutzer"
            name="benutzer"
            value="${this.backendData.lastModifiedBy}"
            label="${this.i18n.translate('geschaeftsvorfall.notiz.benutzer')}"
            readonly
            disabled
          ></isceco-text-input>
          <isceco-date-input
            id="datum"
            name="datum"
            label="${this.i18n.translate('geschaeftsvorfall.notiz.datum')}"
            readonly
            value="${this.backendData.lastModificationTime}"
            disabled
          ></isceco-date-input>
          ${
             this.notizId !== undefined ?
              html`
                <vzavg-document-upload
                  gesuchId="${this.gesuchId}"
                  kategorie="notizen"
                  subKategorie="notiz"
                  objektId="${this.notizId}"
                  .i18n="${this.i18n}"
                  ?readonly="${this.backendData.isReadonly}">
                </vzavg-document-upload>
              ` :
              html``
          }
        </div>
      </div>
    `
  }
}

customElements.define('vzavg-geschaeftsvorfall-notiz-edit', NotizEdit)
