import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import SnapshotService from '../../../services/SnapshotService.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import I18n from '../../../i18n.js'

export default class Snapshot extends WebComponent {

  get css() {
    return css`
      .column3 {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
      }

      .column3:not(:last-child) {
        border-right: 1px solid black;
      }

      .column {
        display: flex;
        flex-direction: column;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Snapshots/i18n.json'
  }

  constructor() {
    super();
    this.snapshotService = new SnapshotService()
  }

  connectedCallback() {
    super.connectedCallback()

    this._changeLanguage = _ => {
      this._getSnapshot()
      // update manualy, because it's not triggered if no data changes, or it has no datas (they are LitElements)
      this._updateAllCustomElements()
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)

    this.betriebId = sessionStorage.getItem('betriebId')
    this.gesuchId = getNavigationId()
    // get id from url
    this.snapshotId = Number(getNavigationIds().split('/')[2])
    if(!this.snapshotId) {
      // the snapshot was not selected in the sub menu, then take the initial value
      this.snapshotId = this.getAttribute('id')
    }

    this._getSnapshot()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)
  }

  _getSnapshot() {
    this.snapshotService.getSnapshot(this.snapshotId, this.betriebId).then(datas => {
      this.snapshot = datas
      window.snapshot$.setValue(this.snapshot);
      this.render()
    })
  }

  getTemplate() {
    return html`
      <div style="display:flex; flex-direction: column; color: gray" >
        <div class="column3">
          <div class="column">
            <betrieb-uebersicht id="snapshot-betrieb" style="pointer-events: none"
              .betrieb="${this.snapshot}"
              gesuchId="${this.gesuchId}"
            ></betrieb-uebersicht>
            <verantwortlichepersonen-uebersicht id="snapshot-verantwortlichepersonen" style="pointer-events: none"
              .verantwortlichePersonen="${this.snapshot.verantwortlichePersonen}"
              gesuchId="${this.gesuchId}"
            ></verantwortlichepersonen-uebersicht>
            <betriebsart-uebersicht id="snapshot-betriebsart" style="pointer-events: none"
              betriebsart="${this.snapshot.betriebsart?.bezeichnung}"
              gesuchId="${this.gesuchId}">
            </betriebsart-uebersicht>
            <adresse-element id="snapshot-revisionsstelle" style="pointer-events: none"
              .adresse="${this.snapshot.revisionsstelle}"
              gesuchId="${this.gesuchId}"
              withTitle
            ></adresse-element>
            <geschaeftsstellen-uebersicht id="snapshot-geschaeftsstellen" style="pointer-events: none"
              .adressen="${this.snapshot.geschaeftsstellen}"
              gesuchId="${this.gesuchId}"
            ></geschaeftsstellen-uebersicht>
            <kaution-uebersicht id="snapshot-kaution" style="pointer-events: none"
              .kautionen="${this.snapshot.kautions}"
            ></kaution-uebersicht>
          </div>
          <div class="column">
            <branchen-uebersicht id="snapshot-branchen" style="pointer-events: none"
              .branchen="${this.snapshot.branchen}"
              gesuchId="${this.gesuchId}"
            ></branchen-uebersicht>
            <qualifikationen-uebersicht id="snapshot-qualifikationen" style="pointer-events: none"
              .qualifikationen="${this.snapshot.qualifikationen}"
              gesuchId="${this.gesuchId}"
            ></qualifikationen-uebersicht>
            <marken-uebersicht id="snapshot-marken" style="pointer-events: none"
              marken="${this.snapshot.marken}"
              gesuchId="${this.gesuchId}"
            ></marken-uebersicht>
            ${AuthorizationFilter.hasKantonRole() ? html`` : html`
            <sonderpeis-uebersicht id="snapshot-sonderpeis" style="pointer-events: none"
              gesuchId="${this.gesuchId}"
              value="${this.snapshot.sonderpreis}"
            ></sonderpeis-uebersicht>
            `}
            <bewilligungen-uebersicht id="snapshot-bewilligungen" style="pointer-events: none"
              .bewilligungs="${this.snapshot.bewilligungs}"
            ></bewilligungen-uebersicht>
          </div>
          <div class="column">
            <adresse-element id="snapshot-hauptsitz" style="pointer-events: none"
              .adresse="${this.snapshot.hauptsitz}"
              gesuchId="${this.gesuchId}"
              withTitle
            ></adresse-element>
            <adresse-element
              id="snapshot-revisionsstelle-hauptsitz" style="pointer-events: none"
              .adresse="${this.snapshot.revisionsstelleHauptsitz}"
              gesuchId="${this.gesuchId}"
              withTitle
            ></adresse-element>
            <zusammenarbeitsverbot-uebersicht id="snapshot-zusammenarbeitsverbot" style="pointer-events: none"
              .zusammenarbeitsverbot="${this.snapshot.zusammenarbeitsverbot}"
              gesuchId="${this.gesuchId}"
            ></zusammenarbeitsverbot-uebersicht>
            <bestaetigungsdatum-uebersicht id="snapshot-bestaetigungsdatum" style="pointer-events: none"
              gesuchId="${this.gesuchId}"
              bestaetigungsDatum="${this.snapshot.bestaetigungsdatum}"
            ></bestaetigungsdatum-uebersicht>
          </div>
        </div>
      </div>
    `
  }

  _updateAllCustomElements() {
    document.getElementById('snapshot-betrieb').requestUpdate()
    document.getElementById('snapshot-verantwortlichepersonen').requestUpdate()
    document.getElementById('snapshot-betriebsart').requestUpdate()
    document.getElementById('snapshot-revisionsstelle').requestUpdate()
    document.getElementById('snapshot-geschaeftsstellen').requestUpdate()
    document.getElementById('snapshot-kaution').requestUpdate()
    document.getElementById('snapshot-branchen').requestUpdate()
    document.getElementById('snapshot-qualifikationen').requestUpdate()
    document.getElementById('snapshot-marken').requestUpdate()
    if(AuthorizationFilter.hasSecoRole()) {
      document.getElementById('snapshot-sonderpeis').requestUpdate()
    }
    document.getElementById('snapshot-bewilligungen').requestUpdate()
    document.getElementById('snapshot-hauptsitz').requestUpdate()
    document.getElementById('snapshot-revisionsstelle-hauptsitz').requestUpdate()
    document.getElementById('snapshot-zusammenarbeitsverbot').requestUpdate()
    document.getElementById('snapshot-bestaetigungsdatum').requestUpdate()
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-snapshot', Snapshot)
