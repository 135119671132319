import {css, html, LitElement} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '../Person/PersonBetriebInfo.js'

class PersonDiffDialog extends LitElement {
  static get styles() {
    return css`
      .div-wrapper {
        display: flex;
        gap: 2em;
      }

      .column {
        flex: 1;
      }

      .changed {
        background-color: var(--isceco-color-yellow-100);
      }

      #overwrite-checkbox {
        margin-left: auto;
        width: 50%;
      }
    `
  }

  /**
   * @typedef Person
   * @property {String} sozVersNr
   * @property {boolean} hasNoSozVersNr
   * @property {String} name
   * @property {String} vorname
   * @property {String} gebDatum
   * @property {String} heimatort
   * @property {String} nationalitaet
   * @property {boolean} aufenthaltsbewilligung
   * @property {String} aufenthaltBis
   */

  static get properties() {
    return {
      /**
       * @type Person
       */
      existingPerson: {type: Object},
      /**
       * @type Person
       */
      newPersonValues: {type: Object},
      changedFields: {type: Set},
      i18n: {type: Object},
      otherBetriebe: {type: Object},
      verantwortlichePersonEditUrl: {type: String}
    }
  }

  constructor() {
    super()
  }

  connectedCallback() {
    super.connectedCallback();

  }

  get dialogDescription() {
    return this.changedFields?.size ?
      this.i18n.translate('geschaeftsvorfall.verantwortliche.person.diff.description')
      : this.i18n.translate('geschaeftsvorfall.verantwortliche.person.existing.description')
  }

  render() {
    return html`
      <isceco-dialog
        hidden
        id="isceco-person-diff-dialog"
        size="large"
        description="${this.dialogDescription}"
        header="${this.i18n.translate('geschaeftsvorfall.dialog.verantwortliche.person.diff.header')}"
        confirm-button="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.confirm')}"
        cancel-button="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.cancel')}"
        @submit="${e => this._close(e)}">
        ${this._renderOtherBetriebe()}
        <div class="div-wrapper">
          <div class="column">
            ${this.renderPersonInfo(this.existingPerson, false, this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.existing'))}
          </div>
          <div class="column">
            ${this.renderPersonInfo(this.newPersonValues, true, this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.new'))}
          </div>
        </div>
        ${this.changedFields?.size ? html`
          <isceco-checkbox
            id="overwrite-checkbox"
            label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.overwrite')}"
          ></isceco-checkbox>
        ` : html``}
      </isceco-dialog>

    `
  }

  /**
   * Person Type Definition
   * @typedef {Object} Person
   * @property {String} [sozVersNr]
   * @property {boolean} hasNoSozVersNr
   * @property {String} name
   * @property {String} vorname
   * @property {String} gebDatum
   * @property {String} [heimatort]
   * @property {String} nationalitaet
   * @property {Boolean} [aufenthaltsbewilligung]
   * @property {Number} [id]
   */

  /**
   *
   * @param {Person} person
   * @param {Boolean} markChange
   * @param {String} title
   * @returns {{_$litType$: *, strings: *, values: *[]}}
   */
  renderPersonInfo(person, markChange, title) {
    if (person) {
      return html`
        <isceco-title id="title-person"
                      text="${title}"
                      size="small">
        </isceco-title>
        <isceco-text-input
          id="${'sozVersNr-' + this._getId(markChange)}"
          name="${'sozVersNr-' + this._getId(markChange)}"
          value="${person.sozVersNr}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.ahvNr')}"
          class="${this._getClasses(markChange, 'sozVersNr')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="${'name-' + this._getId(markChange)}"
          name="${'name-' + this._getId(markChange)}"
          value="${person.name}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.name')}"
          class="${this._getClasses(markChange, 'name')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="${'vorname-' + this._getId(markChange)}"
          name="${'vorname-' + this._getId(markChange)}"
          value="${person.vorname}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.vorname')}"
          class="${this._getClasses(markChange, 'vorname')}"
          readonly
        ></isceco-text-input>
        <isceco-date-input
          id="${'gebDatum-' + this._getId(markChange)}"
          name="${'gebDatum-' + this._getId(markChange)}"
          value="${person.gebDatum}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.gebDatum')}"
          class="${this._getClasses(markChange, 'gebDatum')}"
          readonly
        ></isceco-date-input>
        <isceco-text-input
          id="${'heimatort-' + this._getId(markChange)}"
          name="${'heimatort-' + this._getId(markChange)}"
          value="${person.heimatort}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.heimatOrt')}"
          class="${this._getClasses(markChange, 'heimatort')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="${'nationalitaet-' + this._getId(markChange)}"
          name="${'nationalitaet-' + this._getId(markChange)}"
          value="${person.nationalitaet}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.nationalitaet')}"
          class="${this._getClasses(markChange, 'nationalitaet')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="${'aufenthaltsbewilligung-' + this._getId(markChange)}"
          name="${'aufenthaltsbewilligung-' + this._getId(markChange)}"
          value="${this._getAufenthaltsBewilligungText(person.aufenthaltsbewilligung)}"
          label="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.aufenthaltsbewilligung')}"
          class="${this._getClasses(markChange, 'aufenthaltsbewilligung')}"
          readonly
        ></isceco-text-input>
      `

    }
    return html``
  }

  _renderOtherBetriebe() {
    return this.otherBetriebe?.length ? html`
      <vzavg-person-betrieb-info .companies="${this.otherBetriebe}"
                                 verantwortlichePersonUrl="${this.verantwortlichePersonEditUrl}"
                                 .i18n="${this.i18n}"></vzavg-person-betrieb-info>` : html``
  }

  /**
   * returns id for column 1 for existingData and 2 for the new data
   * @param {Boolean} markChange
   * @private
   */
  _getId(markChange) {
    return markChange ? 2 : 1
  }

  /**
   * returns text for aufenthaltsbewilligung
   * @param {Boolean} hasAufenthaltsBewilligung
   * @returns {*}
   * @private
   */
  _getAufenthaltsBewilligungText(hasAufenthaltsBewilligung) {
    return hasAufenthaltsBewilligung ?
      this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.ja')
      : this.i18n.translate('geschaeftsvorfall.verantwortliche.person.form.nein')
  }

  _getClasses(markChange, fieldName) {
    return markChange && this.changedFields.has(fieldName) ? 'changed' : ''
  }

  /**
   * @namespace PersonDiffTypes
   * @typedef PersonDiffTypes.DiffResult
   * @property {boolean} confirmed
   * @property {boolean} overwrite
   * @property {Person} [person]
   */

  /**
   * calls the callback function with the close dialog infos and closes the dialog
   * @param e DialogCloseEvent
   * @private
   */
  _close(e) {
    const overwrite = this.shadowRoot.querySelector('#overwrite-checkbox')
    /**@type {PersonDiffTypes.DiffResult} **/
    const diffResult = {
      confirmed: e.detail.confirmed,
      overwrite: overwrite?.value ? overwrite.value : false,
      person: this.existingPerson
    }

    this.callback(diffResult)
    this.shadowRoot.querySelector('#isceco-person-diff-dialog')
      .setAttribute('hidden', '')

    // cleanup fields
    this.existingPerson = {}
    this.newPersonValues = {}
    this.changedFields = new Set()
  }
}

customElements.define('vzavg-person-diff-dialog', PersonDiffDialog);
