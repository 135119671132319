import FileInputService from "@isceco/widget-library2/basic-elements/FileInput/FileInputService";

export default class VzavgFileInputServiceDecorator extends FileInputService {
  constructor(decoratorFunction, fileInputService) {
    super('');
    this.decoratorFunction = decoratorFunction
    this.fileInputService = fileInputService
  }

  async upload(file) {
    this.decoratorFunction(this.fileInputService)
    return this.fileInputService.upload(file)
  }
}
