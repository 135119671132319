import {html, LitElement} from '@isceco/widget-library2/external/lit'
import I18n from "../../i18n";

class CheckListOk extends LitElement {

  constructor() {
    super()
    this.hidden = false
    this.disabled = false
    this.clickCallback = () => {};
    this.i18n = new I18n();
  }

  static get properties() {
    return {
      disabled: {type: Boolean},
      hidden: {type: Boolean},
      clickCallback: {type: Function}
    }
  }

  connectedCallback() {
    super.connectedCallback()
    this._refresh = () => {
      this.requestUpdate()
    }
    this.translationLoaded = this.i18n.loadConfiguration('./components/CheckList/i18n.json')
      .then(i18n => {
        document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
        this.requestUpdate()
        return i18n
      })
      .catch(error => {
        this.logging.log(error)
      })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  render() {
    if (this.hidden) {
      return html``;
    } else {
      return html`
        <isceco-button
          id="button-checklistOk"
          text="${this.i18n.translate('checklist.button.ok')}"
          variant="secondary"
          ?disabled="${this.disabled}"
          @click="${() => this.clickCallback()}"
        ></isceco-button>
      `;
    }
  }

}
customElements.define('vzavg-checklist-ok', CheckListOk)
