import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import GesuchService from '../../../services/GesuchService.js'
import KautionColumns from './KautionColumns'
import I18n from '../../../i18n.js'
import {geschaeftsVorfallNavigationLinks, geschaeftsVorfallNavigationLinksHelper} from '../Geschaeftsvorfall.js'
import VzavgListService from '../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import VzavgFormation from '../../../common/VzavgFormation'

export default class Kaution extends WebComponent {

  static get EVENT_KEYS() {
    return {
      TRIGGER_KAUTION_TABLE_RELOAD: 'geschaeftvorfall.kaution.table.reload.event'
    }
  }

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.gesuchService = new GesuchService()
  }

  get css() {
    return css`
      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Kaution/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()

    this.columns = [
      KautionColumns.STATUS_COLUMN(),
      KautionColumns.KAUTIONSDATUM_BIS_COLUMN(),
      KautionColumns.KAUTIONSART_COLUMN(),
      KautionColumns.KAUTIONSGEBER_COLUMN(),
      KautionColumns.KAUTIONSBETRAG_COLUMN(),
      KautionColumns.BEFRIST_BIS_COLUMN(),
      KautionColumns.BLOCKIERT_BIS_COLUMN(),
      KautionColumns.SPERRFRIST_COLUMN(),
      KautionColumns.KAUTIONSSTATUS_COLUMN(),
      KautionColumns.AKTIONEN_COLUMN({
        hasWriteAccess: !AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton,this.isClosedForSeco),
        callback: this._rowActionMenu
      })
    ];

    this.backendData = {sum: 0}

    this._refresh = () => {
      send(Kaution.EVENT_KEYS.TRIGGER_KAUTION_TABLE_RELOAD)

      this.gesuchService.getKautionSum(this.gesuchId)
        .then(res => {
          this.backendData.sum = res.sum
        })
        .then(_ => this.reload())
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    this.gesuchId = getNavigationId()

    this.url = await this.gesuchService.getBackendListUrl(`${this.gesuchId}/kaution`)

    this._refresh()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('geschaeftsvorfall.kaution.header')}">
        <div class="title-content">
          <isceco-button
            id="kaution-add-btn"
            title="${this.i18n.translate('geschaeftsvorfall.kaution.button.create')}"
            icon="plus"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.kautionEdit.hash, id: this.gesuchId})}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton,this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="buttonWeiter"
            variant="primary"
            icon="chevron right"
            @click="${_ => this._handleClickNext()}"
          ></isceco-button>
        </div>
      </isceco-title>
      <p id="kaution-sum-label">${this.i18n.translate('geschaeftsvorfall.kaution.sum.label') + VzavgFormation.formatAmount(this.backendData.sum)}</p>

      ${this._getTemplateList()}
    `
  }

  _getTemplateList() {
    const rowVariant = {
      get: row => {
        if (row.status === 'white') {
          return 'summary'
        }
        return ''
      }
    }
    return html`
      <isceco-list
        id="kautionList"
        .listService="${new VzavgListService(this.url)}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .rowVariant="${rowVariant}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
        order="desc"
        update-event="${Kaution.EVENT_KEYS.TRIGGER_KAUTION_TABLE_RELOAD}"
      ></isceco-list>
    `
  }

  _handleClickNext() {
    const link = geschaeftsVorfallNavigationLinksHelper.next()
    navigate({to: link.hash, id: this.gesuchId})
  }

  _rowActionMenu = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.kautionEdit.hash, id: `${this.gesuchId}/${row.id}`})
    } else if (action === 'history') {
      navigate({to: geschaeftsVorfallNavigationLinks.kautionHistory.hash, id: `${this.gesuchId}/${row.id}`})
    }
  }
}
customElements.define('vzavg-frontend-geschaeftsvorfall-kaution', Kaution)
