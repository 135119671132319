import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/FileInput/FileInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import DokumentService from '../../services/DokumentService.js'
import VzavgFileInputServiceDecorator from "../../services/VzavgFileInputServiceDecorator";

export default class DocumentDokIdUpload extends LitElement {

  static get EVENT_KEYS() {
    return {
      UPDATED: 'dokument-upload-update'
    }
  }

  static get properties() {
    return {
      fileInputId: { type: String },
      gesuchId: { type: String },
      dokumentId: { type: String },
      kategorie: { type: String },
      fileInputService: { type: Object },
      dokIdStammdaten: { type: Object },
      dokumentItems: { type: Object },
      i18n: { type: Object },
      backendData: { type: Object },
      readonly: { type: Boolean },
      currentVersion: { type: Number },
      inlineUpload: { type: Boolean },
    }
  }

  static get styles() {
    return css`
      .flex-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
      }
     .file-content {
         margin: 10px 0px 0px 0px;
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
      .flex-content {
        flex: 0 0 48%;
      }
    `
  }

  constructor() {
    super()
    this.dokumente = {}
    this.dokumente.items = []
    this.validationConfig = {};
    this.readonly = false
    this.currentVersion = 1
  }

  connectedCallback() {
    super.connectedCallback()

    this.dokumentService = new DokumentService(this.gesuchId, this.kategorie+'/file')
    this.dokumentService.validationConfig().then(validationConfig => {
      this.validationConfig = validationConfig;
      this.url = this.dokumentService.backendUrl;
    })
  }

  render() {
    return html`
      <style>
        @import '${iscecoWidgetLibrary.iconCss}';
      </style>
      ${this.inlineUpload ? html`` : this._getFileUpload()}
      ${this.dokumentItems.map(item => html `
        ${this._getFileParagraphTemplate(this.backendData, item.name, item.value, item.mandatory)}
      `)}
    `
  }

  _getFileUpload() {
    return html`
      <div class="flex-container">
         <isceco-dropdown id="combo-dok-name" placeholder="---" label="${this.i18n.translate('geschaeftsvorfall.dokument.upload.dropdown.header')}"
                          ?disabled="${this.readonly}"
                          @change="${e => this._activateFileInputAndSetUrl(e, this.fileInputId, this.fileInputService)}"
                          .items="${this.dokIdStammdaten}">
         </isceco-dropdown>
         <isceco-file-input id="${this.fileInputId}" style="flex-grow: 1;" label="${this.i18n.translate('geschaeftsvorfall.dokument.upload.input.header')}" disabled
            .fileInputService="${this.fileInputService}"
            accept="${this.validationConfig.allowedExtensions}"
            maxsize="${this.validationConfig.maxSize}"
            clear-event="${DocumentDokIdUpload.EVENT_KEYS.UPDATED}"
            @change="${e => this._onDokumentUploaded(e)}">
         </isceco-file-input>
      </div>
        `
  }

  _activateFileInputAndSetUrl(e, idFileInput, service) {
    if(e.detail.value !== '') {
      this.shadowRoot.getElementById(idFileInput).removeAttribute('disabled')

      const alreadyContainedItem = this.backendData.items && this.backendData.items.filter(i => i.dokId === e.detail.value)
      if (alreadyContainedItem && alreadyContainedItem.length > 0) {
        service.setDokId( e.detail.value, alreadyContainedItem[0].version)
      } else {
        service.setDokId( null, null)
      }

      service.setUrl(`${this.url}/${e.detail.value}`)
    } else {
      this.shadowRoot.getElementById(idFileInput).setAttribute('disabled','')
    }
  }

  _getFileParagraphTemplate(backendData, title, dokId, mandatory = false) {
    const dokIdItem = backendData.items && backendData.items.find(item => item.dokId === dokId)
    const service = this.inlineUpload ? new VzavgFileInputServiceDecorator(s => this._setUrl(dokId, s), this.fileInputService) : undefined
    const color = mandatory ? 'red' : 'orange'
    return html`
      <div class="file-content">
        <div class="flex-content">
          ${dokIdItem ? html`<i style="color:green" class="check circle icon"></i>`
            : html`<i style="color:${color}" class="question circle icon"></i>`}
          ${title}
          ${this._getInfoTextPopUp(dokId)}
          ${dokIdItem ? html`${this._getFileContent(dokIdItem)}` : html``}
        </div>
        ${this.inlineUpload ? html`
          <isceco-file-input id="${this.fileInputId}" class="flex-content"
            .fileInputService="${service}"
            accept="${this.validationConfig.allowedExtensions}"
            maxsize="${this.validationConfig.maxSize}"
            clear-event="${DocumentDokIdUpload.EVENT_KEYS.UPDATED}"
            @change="${e => this._onDokumentUploaded(e)}">
         </isceco-file-input>` : html``}

      </div>
    `
  }

  _setUrl(dokId, service) {
    const alreadyContainedItem = this.backendData.items && this.backendData.items.filter(i => i.dokId === dokId)
    if (alreadyContainedItem && alreadyContainedItem.length > 0) {
      service.setDokId(dokId, alreadyContainedItem[0].version)
    } else {
      service.setDokId( null, null)
    }

    service.setUrl(`${this.url}/${dokId}`)
  }


  _getInfoTextPopUp(dokId){
    return dokId === 'PV02' || dokId === 'PV04' || dokId === 'AV09'?
     html`
      <isceco-popup direction="right">
        <i slot="wrapper" class="info circle icon"></i>
        <div slot="content">${this.i18n.translate(`geschaeftsvorfall.dokument.upload.${dokId}.infotext`)}</div>
      </isceco-popup>
    ` :  html``
  }

  _getFileContent(dokIdItem) {
    return html`
      <div style="display: flex; margin: 5px 0px -10px 25px;">
        <i style="margin-top: 5px;" class="file icon"></i>
        <isceco-button variant="tertiary"
                       size="small"
                       text="${this._getShortName(dokIdItem.fileName)}"
                       @click="${_ => this.dokumentService.download(dokIdItem.downloadPath, dokIdItem.fileName)}">
        </isceco-button>
        <isceco-button id="${this._getDeleteBtnId('this.backendData.fileName')}"
                       ?disabled="${this.readonly || dokIdItem.version !== this.currentVersion}"
                       title="${this.i18n.translate('dokumente.delete.button')}"
                       variant="tertiary"
                       size="small"
                       icon="trash"
                       @click="${_ => this._onDeleteClick(dokIdItem.dokId, dokIdItem.version, dokIdItem.fileName)}">
          </isceco-button>
      </div>
    `
  }

  _getDeleteBtnId(name) {
    return 'btn-delete-'.concat(this._getShortName(name).split(' ').join('').replaceAll('.', ''))
  }

  _getShortName(name) {
    const maxLength = 35;
    return name.length > maxLength ? name.substring(0, maxLength).concat('...') : name;
  }

  _onDokumentUploaded(e) {
    if (e.detail.valid) {
      send(DocumentDokIdUpload.EVENT_KEYS.UPDATED)
      this.performUpdate()
    }
  }

  _onDeleteClick(dokId, version, fileName) {
    this.dokumentService.delete(dokId, `${version}/${fileName}`).then(_ => {
      send(DocumentDokIdUpload.EVENT_KEYS.UPDATED)
    });
  }

}
customElements.define('vzavg-document-dokid-upload', DocumentDokIdUpload)
