import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/VerticalNavigation/VerticalNavigation.js'
import WebComponent from '../../../WebComponent.js'
import Navigation from '../../Navigation/Navigation.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import Authorization from '../../Authorization/Authorization.js'

export default class AdministrationNavigation extends WebComponent {

  constructor() {
    super();
  }

  get translationFile() {
    return './views/Administration/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => this.reload();
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  getTemplate() {

    const allItems = {
      Administration_Hilfe: {
        text: this.i18n.translate('navigation.vertical.hilfeinfotext'),
        url: 'Administration_Hilfe'
      },
      Administration_Dokumentation: {
        text: this.i18n.translate('navigation.vertical.dokumentation'),
        url: 'Administration_Dokumentation'
      },
      Administration_Stammdatenpflege: {
        text: this.i18n.translate('navigation.vertical.stammdatenpflege'),
        url: 'Administration_Stammdatenpflege',
        suburls: [
          'Administration_Stammdatenpflege_Kast',
          'Administration_Stammdatenpflege_KastEdit',
          'Administration_Stammdatenpflege_Benutzer',
          'Administration_Stammdatenpflege_BenutzerEdit'
        ]
      },
      Administration_Checklisten: {
        text: this.i18n.translate('navigation.vertical.checklisten'),
        url: 'Administration_Checklisten',
        suburls: [
          'Administration_ChecklistenEdit'
        ]
      },
      Administration_Rechte: {
        text: this.i18n.translate('navigation.vertical.rechte'),
        url: 'Administration_Rechte'
      }
    }
    const items = getNavigationHash() === Authorization.VIEWS().notAuthorized ? null : AuthorizationFilter.viewsAllowed(Object.keys(allItems)).map(key => allItems[key])
    if (items === null) {
      return html``
    }
    items[0].active = true;
    return html`
            <isceco-vertical-navigation
                    .items="${items}">
            </isceco-vertical-navigation>
        `
  }
}
customElements.define('vzavg-frontend-administration-navigation', AdministrationNavigation)
