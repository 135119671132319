import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import TranslationElement from '../../../../common/TranslationElement'
import AuthorizationFilter from "../../../Authorization/AuthorizationFilter";
import UebersichtService from "../../../../services/UebersichtService";

export default class BestaetigungsdatumUebersicht extends TranslationElement {

  static get properties() {
    return {
      bestaetigungsDatum: {type: String},
      gesuchId: {type: String}
    }
  }

  constructor() {
    super()
    this.uebersichtService = new UebersichtService()
  }

  render() {
    if(!this.bestaetigungsDatum?.length) {
      return html`
      ${this._renderTitel()}
      <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.no.date')}</p>
    `
    }
    return html`
      ${this._renderTitel()}
      <isceco-date-input
        id="bestaetigungsdatum"
        name="bestatigungsdatum"
        .value="${this.bestaetigungsDatum}"
        .readonly="${!(this.isKantonUserFromSameKanton || AuthorizationFilter.hasSecoRole()) || this._isSnapshot()}"
        .placeholderEmpty="${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.no.date')}"
        @change="${e => this._updateBestaetigungsdatum(e)}"
      ></isceco-date-input>
    `
  }



  get isKantonUserFromSameKanton() {
    return AuthorizationFilter.hasKantonRole() && AuthorizationFilter.isUserFromSameKanton()
  }

  _updateBestaetigungsdatum(e) {
    const newDate = e.target.getValue()
    if (newDate && newDate.trim().length > 0) {
      const data = {
        field: 'bestaetigungsdatum',
        date: newDate,
      }

      this.gesuchId = getNavigationId()
      this.uebersichtService.updateDate(this.gesuchId, data)
        .then(_ => {
          this.bestaetigungsDatum = newDate
          this.render()
        })
    } else {
      e.target.value = this.bestaetigungsDatum
      this.render()
    }
  }

  _isSnapshot() {
    return getNavigationHash().includes('Snapshots')
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BestaetigungsdatumUebersicht/i18n.json'
  }

  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.title')}"
        resource="${geschaeftsVorfallNavigationLinks.entscheid.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }
}
customElements.define('bestaetigungsdatum-uebersicht', BestaetigungsdatumUebersicht)
